import { BiSolidInfoSquare } from "react-icons/bi";
import { useNavigate } from "react-router-dom";

const HelpDialog = ({ trails, setShowHelp }) => {
  return (
    <div className=" absolute -right-[10%] top-16 z-50 flex h-36 animate-animateFadeIn sm:w-48 sm-max:w-32 md:w-60 lg:w-72 xl:w-72">
      <div className="charthelp ipad11-max:h-38 absolute right-[13%] top-[35%] flex h-fit w-[16vw] flex-col items-center justify-between gap-0 rounded-[20px] p-3">
        <span className="font-semibold text-[#26337C] sm:text-sm sm-max:text-xs md:text-sm lg:text-base xl:text-lg">
          Click the "Retry" button and try again. You have {4 - trails} trials
          left
        </span>
        <button
          className="button-class rounded-md px-5 py-[2px] text-sm font-semibold text-white underline-offset-2 hover:bg-[#3072D6]"
          onClick={() => {
            setShowHelp(false);
          }}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

const ContinueDialog = ({ showCorrectAnswer }) => {
  return (
    <div className=" absolute -right-[10%] top-16 z-50 flex h-36 animate-animateFadeIn sm:w-48 sm-max:w-32 md:w-60 lg:w-72 xl:w-72">
      <div className="charthelp ipad11-max:h-38 absolute right-[13%] top-[35%] flex h-fit w-[16vw] flex-col items-center justify-between gap-0 rounded-[20px] p-3">
        <span className="font-semibold text-[#26337C] sm:text-sm sm-max:text-xs md:text-sm lg:text-base xl:text-lg">
          Click the "Continue" button
        </span>
        <button
          className="button-class rounded-md px-5 py-[2px] text-sm font-semibold text-white underline-offset-2 hover:bg-[#3072D6]"
          onClick={() => {
            showCorrectAnswer(false);
          }}
        >
          Got it
        </button>
      </div>
    </div>
  );
};

const ShowCorrectAnswerDialog = ({
  setIsModalOpen,
  trails,
  setTrails,
  showCorrectAnswer,
}) => {
  return (
    <>
      <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]">
        <div
          className="flex w-[600px] flex-col items-center gap-6 rounded-[20px] border-2 border-[#9DC0EE] bg-[#F5F9FF] px-10 pb-6 pt-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex flex-row gap-2">
            <BiSolidInfoSquare className="h-12 w-28 text-[#066AB2]" />
            <span className="text-lg font-medium">
              You have tried {trails} times so far, would you like AI to show
              the Correct Answer for you?
            </span>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <button
              className="rounded-md border border-[#979FA9] bg-white px-3 py-[3px] text-lg shadow-md hover:bg-[#b0b4d0ac] hover:bg-opacity-10"
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
                setTrails(1);
              }}
            >
              No
            </button>
            <button
              className={`button-class h-9 rounded-md px-5 text-lg font-medium text-white hover:bg-[#3072D6]`}
              onClick={(e) => {
                e.stopPropagation();
                setIsModalOpen(false);
                setTrails(1);
                showCorrectAnswer(true);
              }}
            >
              Yes
            </button>
          </div>
        </div>
      </div>
      <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
    </>
  );
};

const CongratsDialog = ({ setIsGameCompletedModalOpen }) => {
  const navigate = useNavigate();
  return (
    <>
      <div
        className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"
        onClick={() => {
          setIsGameCompletedModalOpen(false);
        }}
      >
        <div
          className="flex flex-col items-center gap-6 rounded-[20px] border-2 border-[#F0B424] bg-[#FFF8EC] px-10 pb-6 pt-8"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          <div className="flex flex-row items-start justify-start gap-2">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={1}
              stroke="#ECB83D"
              className="h-16 w-20"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
              />
            </svg>

            {/* <BiTrophy className="h-16 w-20 text-[#FFDA21]" /> */}
            <div className="flex flex-col gap-2">
              <span className="text-xl font-semibold  tracking-wide text-[#2F3F53]">
                Congratulations!
              </span>
              <span className="text-lg  font-medium tracking-wider text-[#2F3F53]">
                You have completed Game 2 - Setting Inventory
              </span>
            </div>
          </div>
          <div className="flex flex-row items-center justify-center gap-3">
            <button
              className="rounded-md border border-[#979FA9] bg-white px-5 py-[3px] text-lg font-semibold shadow-md hover:bg-[#edeffeac] hover:bg-opacity-10"
              onClick={(e) => {
                e.stopPropagation();
                setIsGameCompletedModalOpen(false);
              }}
            >
              BACK
            </button>
            <button
              className="rounded-md bg-[#F0B424] px-5 py-[3px] text-lg font-semibold text-white shadow-md hover:bg-[#ead787] "
              onClick={(e) => {
                e.stopPropagation();
                navigate("/game/game3");
              }}
            >
              NEXT
            </button>
          </div>
        </div>
      </div>
      <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
    </>
  );
};
export { HelpDialog, ContinueDialog, ShowCorrectAnswerDialog, CongratsDialog };
