import React, { useState, useEffect, ChangeEvent } from "react";
import Radio from "components/radio";
import { Image, HStack, Box } from "@chakra-ui/react";
import image1 from "assets/img/distribution/Distribution1.png";
import image2 from "assets/img/distribution/Distribution2.png";
import image3 from "assets/img/distribution/Distribution3.png";
import image4 from "assets/img/distribution/Distribution4.png";
import image5 from "assets/img/distribution/Distribution5.png";
import { Tooltip } from "react-tooltip";
import "./index.css";
interface RadioDistoProps {
  onChange: (selectedOption: number) => void;
}

const RadioDisto: React.FC<RadioDistoProps> = ({ onChange }) => {
  const initialSelectedOption = parseInt(
    localStorage.getItem("radio") || "",
    10
  );
  const [selectedOption, setSelectedOption] = useState<number>(
    initialSelectedOption
  );

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const selectedOption = parseInt(event.target.value, 10);
    localStorage.setItem("radio", event.target.value.toString());
    setSelectedOption(selectedOption);
    onChange(selectedOption);
  };

  useEffect(() => {
    var gameStoreData = localStorage.getItem("game-store");

    if (gameStoreData) {
      var gameStoreObject = JSON.parse(gameStoreData);
      localStorage.setItem("game-store", JSON.stringify(gameStoreObject));
    }

    const storedRadio = localStorage.getItem("radio");

    if (storedRadio !== null) {
      setSelectedOption(parseInt(storedRadio, 10));
    }
  }, []);

  return (
    <div className="flex flex-col">
      <div className="mb-2 text-sm font-bold text-white sm:text-xs sm-max:text-xs md:text-sm xl:text-lg">
        Select a Distribution
      </div>
      <Tooltip
        id="tooltip-1"
        place="top"
        content="Actual Probability Distribution"
        border="true"
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        className="custom-tooltip"
      />
      <Tooltip
        id="tooltip-2"
        place="top"
        content="Uniform Probability Distribution"
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        className="custom-tooltip"
      />
      <Tooltip
        id="tooltip-3"
        place="top"
        content="Bimodal Probability Distribution"
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        className="custom-tooltip"
      />
      <Tooltip
        id="tooltip-4"
        place="top"
        content="Single Value Distribution"
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        className="custom-tooltip"
      />
      <Tooltip
        id="tooltip-5"
        place="top"
        content="Low Variance Distribution"
        style={{
          backgroundColor: "white",
          fontWeight: "bold",
          fontSize: "16px",
        }}
        className="custom-tooltip"
      />
      <HStack
        direction="row"
        css={{
          gap: "0.3vw",
          "@media (min-width: 768px)": {
            gap: "0.1vw",
          },
          "@media (min-width: 992px)": {
            gap: "0.1vw",
          },
          "@media (min-width: 1180px)": {
            gap: "0.4vw",
          },
          "@media (min-width: 1200px)": {
            gap: "0.5vw",
          },
        }}
      >
        <Radio
          id="APD"
          name="type"
          className="form-radio text-center sm:w-2 md:w-3 lg:w-4 xl:w-5 2xl:w-6"
          label="Actual Probabilty Distribution"
          onChange={handleChange}
          value={1}
          checked={selectedOption === 1}
        />
        <Box
          w="8vw"
          h="3vh"
          // className="transform transition-transform hover:scale-x-125 hover:scale-y-150 focus:scale-150 active:scale-150"
        >
          <Image
            objectFit="cover"
            src={image1}
            alt="Actual Probability Distribution"
            data-tooltip-id="tooltip-1"
          />
        </Box>

        <Radio
          id="UPD"
          name="type"
          className="form-radio sm:w-2 md:w-3 lg:w-4 xl:w-5 2xl:w-6"
          label="Uniform Probabilty Distribution"
          onChange={handleChange}
          value={2}
          checked={selectedOption === 2}
        />
        <Box
          w="8vw"
          h="3vh"
          //className="transform transition-transform hover:scale-x-125 hover:scale-y-150 focus:scale-150 active:scale-150"
        >
          <Image
            objectFit="cover"
            src={image2}
            alt="Uniform Probabilty Distribution"
            data-tooltip-id="tooltip-2"
          />
        </Box>
        <Radio
          id="BPD"
          name="type"
          className="form-radio sm:w-2 md:w-3 lg:w-4 xl:w-5 2xl:w-6"
          label="Bimodal Probabilty Distribution "
          onChange={handleChange}
          value={3}
          checked={selectedOption === 3}
        />
        <Box
          w="8vw"
          h="3vh"
          //className="transform transition-transform hover:scale-x-125 hover:scale-y-150 focus:scale-150 active:scale-150"
        >
          <Image
            objectFit="cover"
            src={image3}
            alt="Bimodal Probabilty Distribution"
            data-tooltip-id="tooltip-3"
          />
        </Box>
        <Radio
          id="SVD"
          name="type"
          className="form-radio sm:w-2 md:w-3 lg:w-4 xl:w-5 2xl:w-6"
          label="Single Value Distribution"
          onChange={handleChange}
          value={4}
          checked={selectedOption === 4}
        />
        <Box
          w="8vw"
          h="3vh"
          //className="transform transition-transform hover:scale-x-125 hover:scale-y-150 focus:scale-150 active:scale-150"
        >
          <Image
            objectFit="cover"
            src={image4}
            alt="Single Value Distribution"
            data-tooltip-id="tooltip-4"
          />
        </Box>
        <Radio
          id="LVD"
          name="type"
          className="form-radio sm:w-2 md:w-3 lg:w-4 xl:w-5 2xl:w-6"
          label="Low Variance Distribution"
          onChange={handleChange}
          value={5}
          checked={selectedOption === 5}
        />
        <Box
          w="8vw"
          h="3vh"
          //className="transform transition-transform hover:scale-x-125 hover:scale-y-150 focus:scale-150 active:scale-150"
        >
          <Image
            objectFit="cover"
            src={image5}
            alt="Low Variance Distribution"
            data-tooltip-id="tooltip-5"
          />
        </Box>
      </HStack>
    </div>
  );
};
export default RadioDisto;
