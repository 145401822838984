import { Routes, Route, Navigate } from "react-router-dom";

// import RtlLayout from "layouts/rtl";
import AdminLayout from "layouts/admin";
import AuthLayout from "layouts/auth";
import InstructorLayout from "layouts/InstructorLayout";

import Pusher from "pusher-js";
import { PusherProvider } from "./context/pusher/PusherContext";

const App = () => {
  const pusherId = process.env.REACT_APP_PUSHER_ID
  const cluster = process.env.REACT_APP_PUSHER_CLUSTER

  const pusher = new Pusher(pusherId, {
    cluster: cluster,
    forceTLS: true,
    activityTimeout : 150000
  });

  return (
    <PusherProvider pusher={pusher}>
      <Routes>
        <Route path="auth/*" element={<AuthLayout />} />
        <Route path="instructor/*" element={<InstructorLayout />} />
        <Route path="game/*" element={<AdminLayout />} />
        <Route path="/" element={<Navigate to="/auth/login" replace />} />
      </Routes>
    </PusherProvider>
  );
};

export default App;
