import { Input } from "@chakra-ui/react";
import { useEffect } from "react";
import "./button.css";

/* eslint-disable react-hooks/exhaustive-deps */

interface InputWithIconProps {
  pintBuyChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pintSellChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
  pintBuy: string;
  pintSell: string;
  onEnterClick: () => void;
}
const invalidKeys = /^[:-~ --]$/;
export default function InputWithIcon({
  pintBuyChange,
  pintSellChange,
  pintBuy,
  pintSell,
  onEnterClick,
}: InputWithIconProps) {
  useEffect(() => {
    // Retrieve values from localStorage on component mount (page load)
    const storedPintBuy = localStorage.getItem("pintBuy");
    const storedPintSell = localStorage.getItem("pintSell");

    if (storedPintBuy !== null) {
      pintBuyChange({
        target: { value: storedPintBuy },
      } as React.ChangeEvent<HTMLInputElement>);
    }

    if (storedPintSell !== null) {
      pintSellChange({
        target: { value: storedPintSell },
      } as React.ChangeEvent<HTMLInputElement>);
    }
  }, []);

  return (
    <div className="flex items-end">
      <div className="ms-2 flex flex-col">
        <span className="mb-2 text-sm font-bold text-white sm:text-[8px] sm:leading-4 md:text-[10px] md:leading-5 lg:text-[12px] lg:leading-6  xl:text-[14px] xl:leading-7 2xl:text-[16px]">
          Pint Buy Price
        </span>
        <Input
          type="number"
          min={0}
          id="buy-price" 
          //className="md:text-md h-10 rounded-md bg-[#D8D8D852] ps-1 font-black text-yellow-300 sm:h-5 sm:w-16 sm:text-sm md:w-20 md:h-7 lg:h-7 lg:w-24 xl:h-8 xl:w-28  xl:text-2xl 2xl:h-10 2xl:w-32"
          className="md:text-md h-10 w-[100%] rounded-md bg-[#D8D8D852] ps-1 font-black text-yellow-300 sm:h-5  sm:text-sm md:h-7  lg:h-7   xl:h-8 xl:text-2xl 2xl:h-10"
          // placeholder="Enter amount"
          onChange={pintBuyChange}
          value={pintBuy}
          onKeyDown={(evt) =>
            invalidKeys.test(evt.key) && evt.preventDefault()
          }
        />
      </div>
      <div className="ms-2 flex flex-col">
        <span className="mb-2 text-sm font-bold text-white sm:text-[8px] sm:leading-4 md:text-[10px] md:leading-5 lg:text-[12px] lg:leading-6  xl:text-[14px] xl:leading-7 2xl:text-[16px]">
          Pint Sell Price
        </span>
        <Input
          type="number"
          min={0}
          id="sell-price" 
          //className="md:text-md h-10 rounded-md bg-[#D8D8D852] ps-1 font-black text-yellow-300 sm:h-5 sm:w-16 sm:text-sm md:w-20 md:h-7 lg:h-7 lg:w-24 xl:h-8 xl:w-28 xl:text-2xl 2xl:h-10 2xl:w-32"
          className="md:text-md h-10 w-[100%] rounded-md bg-[#D8D8D852] ps-1 font-black text-yellow-300 sm:h-5  sm:text-sm md:h-7  lg:h-7   xl:h-8 xl:text-2xl 2xl:h-10"
          // placeholder="Enter amount"
          onChange={pintSellChange}
          value={pintSell}
          onKeyDown={(evt) =>
            invalidKeys.test(evt.key) && evt.preventDefault()
          }
        />
        {/* <InputGroup size="xs">
        <InputLeftElement
          pointerEvents="none"
          children={<BsCurrencyDollar className="text-yellow-400 absolute left-0 bottom-0" size={24} color="yellow.300" />}
        />
        <Input className="sm:text-xs md:text-xl xl:text-2xl sm-max:w-8 sm:w-12 lg:w-24 xl:w-32 2xl:w-40 sm:h-4 lg:h-6 xl:h-8 2xl:h-10 rounded-md bg-[#D8D8D852] text-yellow-300 h-10 font-black"defaultValue={0} variant="filled"/>
      </InputGroup> */}
      </div>
      <button
        //className="ms-5 align-center rounded-md bg-gradient-to-br  from-[#279DF3] to-[#0466AD] text-center font-black text-white transition duration-200 hover:shadow-lg hover:shadow-[#3072D6] sm:h-5 sm:w-16 sm:text-[10px] md:w-20 md:h-7  md:text-xs lg:h-7 lg:w-24 lg:text-lg xl:h-8 xl:w-28 xl:text-xl 2xl:h-10 2xl:w-32"
        className="align-center ms-2 w-[70%] rounded-md  bg-gradient-to-br from-[#279DF3] to-[#0466AD] text-center font-black text-white transition duration-200 hover:shadow-lg hover:shadow-[#3072D6] sm:h-5  sm:text-[10px]  md:h-7 md:text-xs lg:h-7 lg:text-base xl:h-8 xl:text-xl 2xl:h-10"
        onClick={onEnterClick}
      >
        Enter
      </button>
    </div>
  );
}
