import HistoricalDataTable from "./components/Table/historical.tsx";

interface HistoricalData {
  id: number;
  user_id: number;
  username: string;
  team_id: number;
  teamname: string;
  pe_id: number;
  price: number;
  period: string;
  sale: number;
  revenue: number;
  cumulative_revenue: number;
  beginning_inventory: number;
  ending_inventory: number;
  date: string;
  created_at: string;
}

interface HistoricalDataProps {
  historicaldata: HistoricalData[];
  handleBackClick: () => void;
}

const HistoricalDataComponent: React.FC<HistoricalDataProps> = ({
  historicaldata,
  handleBackClick,
}) => {
  return (
    <>
      <div className="mx-32 flex h-screen flex-col lg-max:hidden">
        <div className="md:h-14 lg:h-12 xl:h-16">
          <div className="item-start z-1 mb-1 h-3/4">
            <div id="HistoricalData">
              {Array.isArray(historicaldata) && historicaldata.length > 0 ? (
                <HistoricalDataTable data={historicaldata} handleBackClick={handleBackClick} />
              ) : (
                <div></div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
export default HistoricalDataComponent;
