// About Imports
import About from "views/about";

//Leaderboard
import Leaderboard from "views/game3/leaderboard/leaderboard";

//HistoricalData
import HistoricalData from "views/instructor/HistoricalData/index.tsx";

//ResetTeam
import ResetTeam from "views/instructor/Reset/index.tsx";

//Alerts
import Alerts from "views/instructor/Alerts/index.tsx";

const instructorroutes = [
  {
    name: "About",
    layout: "/instructor",
    path: "about",
    component: <About />,
  },

  {
    name: "Leaderboard",
    layout: "/instructor",
    path: "leaderboard",
    component: <Leaderboard />,
  },

  // {
  //   name: "HistoricalData",
  //   layout: "/instructor",
  //   path: "historicaldata",
  //   component: <HistoricalData />,
  // },
  // {
  //   name: "Reset",
  //   layout: "/instructor",
  //   path: "ResetTeam",
  //   component: <ResetTeam />,
  // },
  {
    name: "SendAlert",
    layout: "/instructor",
    path: "alert",
    component: <Alerts />,
  },
];

export default instructorroutes;
