import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../config/AuthDataProvider";
const AboutBox = () => {
  const authData = useAuth();
  const navigate = useNavigate();

  const navigateToGame1 = () => {
    if (authData && authData.isPlayer) {
      navigate("/game/game1A");
    }
  };
  const navigateToGame2 = () => {
    if (authData && authData.isPlayer) {
      navigate("/game/game2");
    }
  };
  const navigateToGame3 = () => {
    if (authData && authData.isPlayer) {
      navigate("/game/game3");
    }
  };
  return (
    <div className="relative w-[50%] flex-col items-start justify-center">
      <div className="relative flex w-full items-center justify-center text-center">
        <div className="circle-container absolute -top-[1.2rem] left-10 z-40 flex items-center justify-center font-poppins font-semibold">
          Descriptive & Predictive
        </div>
      </div>
      <div
        onClick={navigateToGame1}
        className="custom-hexagon custom-color flex h-[20%] w-[120%] scale-x-95
         transform-gpu items-center border-y-4 border-l-4 hover:scale-x-100 hover:cursor-pointer"
      >
        <div className="w-[50%] flex-col justify-center">
          <div className="flex text-sm font-medium text-white lg:pl-44 xl:pl-48">
            PLAYING GOAL
          </div>

          <div className="text-md flex text-2xl font-black text-white lg:pl-44 xl:pl-48">
            Predict Beer Sales
          </div>
        </div>
        <button className="custom-btn relative m-9 h-6 w-20 flex-row rounded-md text-white shadow-red-400 drop-shadow-md">
          START
        </button>
      </div>

      <div
        onClick={navigateToGame2}
        className="custom-hexagon custom-color mt-10 flex h-[20%] w-[120%] scale-x-95  transform-gpu items-center border-y-4 border-l-4 hover:scale-x-100 hover:cursor-pointer"
      >
        <div className="w-[50%] flex-col items-center">
          <div className="mt-2 flex text-sm font-medium text-white lg:pl-44 xl:pl-48">
            PLAYING GOAL
          </div>
          <div className="text-md flex text-2xl font-black text-white lg:pl-44 xl:pl-48">
            Set Inventory
          </div>
        </div>
        <button className="custom-btn relative m-9  h-6 w-20 flex-row rounded-md text-white shadow-red-400 drop-shadow-md">
          START
        </button>
      </div>

      <div className="relative flex w-full items-center justify-center text-center">
        <div className="rhombus absolute -top-[2.2rem] left-14 z-40 flex items-center justify-center">
          <div className="item-count font-poppins font-semibold">
            Prescriptive "Decisioning"
          </div>
        </div>
      </div>
      <div
        onClick={navigateToGame3}
        className="custom-hexagon custom-color mt-10 flex h-[20%] w-[120%] scale-x-95 transform-gpu items-center border-y-4 border-l-4 hover:scale-x-100 hover:cursor-pointer"
      >
        <div className="w-[50%] flex-col">
          <div className="mt-2 flex text-sm font-medium text-white lg:pl-44 xl:pl-48">
            PLAYING GOAL
          </div>
          <div className="text-md flex text-2xl font-black text-white lg:pl-44 xl:pl-48">
            Dynamic Pricing
          </div>
        </div>
        <button className="custom-btn relative m-9  h-6 w-20 flex-row rounded-md text-white shadow-red-400 drop-shadow-md">
          START
        </button>
      </div>
    </div>
  );
};

export default AboutBox;
