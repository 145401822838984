import { useGameStore } from "../../../store/store";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { toast } from "react-toastify";
import {
  ArrowDownIcon,
  ArrowsUpDownIcon,
  InformationCircleIcon,
} from "@heroicons/react/20/solid";
import { Direction, Range } from "react-range";
import { useEffect, useState } from "react";
import { ImArrowLeft } from "react-icons/im";

export default function Game1AStep1() {
  const step1IsGrouped = useGameStore((state) => state.step1IsGrouped);
  const step1IsSorted = useGameStore((state) => state.step1IsSorted);
  const setStep1IsGrouped = useGameStore((state) => state.setStep1IsGrouped);
  const setStep1IsSorted = useGameStore((state) => state.setStep1IsSorted);
  const patronList = useGameStore((state) => state.game1AStep1PatronList);
  const step1SliderValue = useGameStore((state) => state.step1SliderValue);
  const resetGame = useGameStore((state) => state.Game1reset);
  const setStep1SliderValue = useGameStore(
    (state) => state.setStep1SliderValue
  );
  const sortPatronListBy = useGameStore((state) => state.sortPatronListBy);
  const step1GroupIds = useGameStore((state) => state.step1GroupIds);
  const setStep1Group = useGameStore((state) => state.setStep1Group);
  const incrementCurrentStep = useGameStore(
    (state) => state.incrementCurrentStep
  );
  const [showHelp, setShowHelp] = useState(true);
  const [parent] = useAutoAnimate();
  const [isBlurActive, setIsBlurActive] = useState(false);

  // document.addEventListener("click", () => {
  //   setShowHelp(false);
  // });

  useEffect(() => {
    resetGame();
  }, []);

  const buttonStyle =
    "w-1/2 mb-2 text-white text-base rounded-md hover:-translate-y-1 ease-in-out duration-300 button-class py-[3px]  hover:bg-[#3072D6]";
  // </button> //   Sort patrons by actual daily // > //   }} //     setStep1IsSorted(true); //     sortPatronListBy("actualDaily"); //     e.preventDefault(); //   onClick={(e) => { //   type="button" //   className={buttonStyle} // <button
  return (
    <div className="relative flex h-full w-full flex-col items-center justify-center">
      <div className="flex w-full flex-row items-center justify-center text-center">
        <div className=" md:text-md relative mt-4 flex w-1/2 justify-center rounded-t-lg bg-white px-7 ps-2  pt-2 font-bold text-[#26337C] sm:text-base sm-max:text-xs lg:text-lg xl:text-[20px]">
          {!step1IsSorted ? (
            "How would you segment the patrons based on the data below?"
          ) : !step1IsGrouped ? (
            "Select a row or click and drag the arrow icon to group patrons"
          ) : (
            <>Good Job! Now click &quot;Next&quot; to continue</>
          )}
          <button
            className="absolute right-0 top-2"
            onClick={() => {
              setShowHelp(true);
            }}
          >
            <InformationCircleIcon className=" h-6 w-6 text-[#2B3F6C]" />
          </button>
          {showHelp ? (
            <div className=" absolute -right-[15%] top-16 z-50 flex h-36 animate-animateFadeIn sm:w-48 sm-max:w-32 md:w-60 lg:w-72 xl:w-72">
              <div className="help ipad11-max:h-38 absolute right-[20%] top-[50%] flex h-fit w-[13vw] flex-col items-center justify-between gap-0 rounded-[20px] p-3">
                <span className="font-semibold text-[#26337C] sm:text-sm sm-max:text-xs md:text-sm lg:text-base xl:text-lg">
                  Drag the arrow up or down and press “Continue”
                </span>
                <button
                  className="button-class rounded-md px-5 py-[2px] text-sm font-semibold text-white underline-offset-2 hover:bg-[#3072D6]"
                  onClick={() => {
                    setShowHelp(false);
                  }}
                >
                  Got it
                </button>
              </div>
            </div>
          ) : null}
        </div>
      </div>
      <div className="relative flex w-1/2 items-center justify-center rounded-b-lg bg-white">
        <div className="flex w-48 flex-col items-center justify-center">
          <div className="mt-2 flex w-[100%] animate-animateFadeIn flex-row rounded-lg bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
            <div className="border-black pl- flex h-12 w-2/5 items-center justify-center rounded-l-lg border-2 text-center text-lg font-semibold">
              Patrons
            </div>
            <button
              id="sortButton"
              disabled={step1IsSorted}
              onClick={(e) => {
                e.preventDefault();
                sortPatronListBy("actualDaily");
                setStep1IsSorted(true);
                setStep1Group([]);
                setStep1IsGrouped(false);
                setStep1SliderValue([0]);
              }}
              className="border-black text-md relative flex h-12 w-3/5 items-center rounded-r-lg border-2 border-l-0 text-center font-semibold"
            >
              <div>Previous Friday Sale</div>
              {step1IsSorted ? (
                <ArrowDownIcon className="relative right-0 h-6 w-6" />
              ) : (
                <ArrowsUpDownIcon className="relative right-0 h-6 w-6" />
              )}
            </button>
          </div>
          <div id="slider-div" className="relative mb-2 flex">
            <ul
              ref={parent}
              className="mb-2 mt-2 flex animate-animateFadeIn flex-col gap-1"
            >
              {patronList.map((patron, index) => (
                <div
                  id={`patron${patron.id}`}
                  key={patron.id}
                  className="flex w-48 flex-row rounded-[6px] border-[1px] border-solid  border-[#DFE8FD]  bg-[#EAEEFF85] text-sm"
                >
                  <div className="flex h-[20px] w-2/5 items-center justify-center border-r border-[#DFE8FD] text-center">
                    {patron.name}
                  </div>
                  <div
                    className={`flex h-[20px] w-3/5 items-center justify-center rounded-r-[6px] ${
                      step1IsGrouped
                        ? step1GroupIds.includes(patron.id)
                          ? "bg-color1"
                          : "bg-color2"
                        : "bg-[#C9A0CA26]"
                    } ${step1IsSorted ? "hover:cursor-pointer" : ""}`}
                    onClick={(e) => {
                      e.preventDefault();
                      if (!step1IsSorted) {
                        return;
                      }
                      if (index === patronList.length - 1) {
                        setIsBlurActive(true);
                        toast.error(
                          <>
                            <div className="font-Helvetica text-lg font-bold tracking-wide">
                              Incorrect segmentation
                            </div>
                            <div className="font-Helvetica text-sm tracking-wide">
                              Groups should have atleast one element!
                            </div>
                          </>,
                          {
                            position: "top-center",
                            autoClose: 5000,
                            hideProgressBar: false,
                            closeOnClick: true,
                            pauseOnHover: true,
                            draggable: true,
                            progress: undefined,
                            theme: "colored",
                            onClose: () => {
                              setIsBlurActive(false);
                            },
                          }
                        );
                        return;
                      }
                      setStep1Group(
                        patronList
                          .slice(0, index + 1)
                          .map((patron) => patron.id)
                      );
                      setStep1SliderValue([index]);
                      setStep1IsGrouped(true);
                    }}
                  >
                    {patron.actualDaily}
                  </div>
                </div>
              ))}
            </ul>

            <Range
              min={0}
              max={18}
              values={step1SliderValue}
              // onFinalChange={() => setStep1IsGrouped(true)}
              onChange={(values) => {
                setStep1SliderValue(values);
                setStep1Group(
                  patronList.slice(0, values[0] + 1).map((patron) => patron.id)
                );
                setStep1IsGrouped(true);
                setShowHelp(false);
              }}
              direction={Direction.Down}
              renderTrack={({ props, children }) => (
                <div
                  id="move-slider"
                  className="absolute -right-5 top-5 z-40 h-[467px]"
                  {...props}
                  style={{
                    ...props.style,
                  }}
                >
                  {children}
                </div>
              )}
              renderThumb={({ props }) => (
                <>
                  <ImArrowLeft
                    {...props}
                    className="slider-element z-10 h-9 w-9 text-[#2B3F6C] !outline-none"
                  />
                </>
              )}
            />
          </div>
          {isBlurActive && (
            <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
          )}

          <button
            className={buttonStyle}
            // disabled={!step1IsSorted || step1SliderValue[0] !== 11}
            onClick={(e) => {
              if (!step1IsSorted || step1SliderValue[0] !== 11) {
                setIsBlurActive(true);
                toast.error(
                  <>
                    <div className="font-Helvetica text-lg font-bold tracking-wide">
                      Incorrect segmentation
                    </div>
                    <div className="font-Helvetica text-sm tracking-wide">
                      Please try again!
                    </div>
                  </>,
                  {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: false,
                    draggable: false,
                    progress: undefined,
                    theme: "colored",
                    onClose: () => {
                      setIsBlurActive(false);
                    },
                  }
                );
              } else {
                incrementCurrentStep();
              }
            }}
          >
            Continue
          </button>
        </div>
        {step1IsGrouped && step1IsSorted ? (
          <div className="absolute bottom-16 right-1 ms-20 h-10 w-[22%] animate-animateFadeIn flex-col rounded-lg bg-[#0C399F]">
            <div className="border-slate-400 w-full rounded-t-lg py-1 pl-1 font-semibold ">
              Keys
            </div>
            <div className="w-full border-l border-r border-color1 bg-color1 py-1 pl-2 text-[1vw]">
              Segment 1: &nbsp; &lt;=
              {
                patronList.find(
                  (patron) =>
                    patron.id === step1GroupIds[step1GroupIds.length - 1]
                )?.actualDaily
              }{" "}
              &nbsp; Pints
            </div>
            <div className="w-full rounded-b-lg border-b border-color2 bg-color2 py-1 pl-2 text-[1vw]">
              Segment 2: &nbsp;&gt;=
              {
                patronList[
                  patronList.findIndex(
                    (patron) =>
                      patron.id === step1GroupIds[step1GroupIds.length - 1]
                  ) + 1
                ].actualDaily
              }{" "}
              &nbsp; Pints
            </div>
          </div>
        ) : null}
      </div>
    </div>
  );
}
