import { create } from "zustand";
import { Game1ASlice, createGame1ASlice } from "./game1A/game1ASlice";
import { immer } from "zustand/middleware/immer";
import { persist } from "zustand/middleware";
import { Game1BSlice, createGame1BSlice } from "./game1B/game1BSlice";
import { Game2Slice, createGame2Slice } from "./game2/game2Slice";
import { Game3Slice, createGame3Slice } from "./game3/game3Slice";
import { authSlice, createauthSlice } from "./auth/authSlice";

type StoreSlices = Game1ASlice & Game1BSlice & Game2Slice & authSlice & Game3Slice;

export const useGameStore = create<StoreSlices>()(
  persist(
    immer<StoreSlices>((...args) => ({
      ...createGame1ASlice(...args),
      ...createGame1BSlice(...args),
      ...createGame2Slice(...args),
      ...createGame3Slice(...args),
      ...createauthSlice(...args),
    })),
    { name: "game-store" }
  )
);