import { useMemo, useState } from "react";
import { useGameStore } from "../../../store/store";
import { useAutoAnimate } from "@formkit/auto-animate/react";
import { Patron } from "../../../store/game1A/game1ASlice";
import { toast } from "react-toastify";
import { CheckCircleIcon, XCircleIcon } from "@heroicons/react/24/outline";
import { useNavigate } from "react-router-dom";
import { InformationCircleIcon } from "@heroicons/react/24/solid";

export default function Game1AStep2() {
  const navigate = useNavigate();
  const [isClustered, setIsClustered] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const setIsGame1ACompleted = useGameStore(
    (state) => state.setIsGame1ACompleted
  );
  const patronList = useGameStore((state) => state.game1AStep1PatronList);
  const step1GroupIds = useGameStore((state) => state.step1GroupIds);
  const step2FirstGroupIds = useGameStore((state) => state.step2FirstGroupIds);
  const step2SecondGroupIds = useGameStore(
    (state) => state.step2SecondGroupIds
  );
  const clusterPatronList = useGameStore((state) => state.clusterPatronList);
  const clusters = useGameStore((state) => state.clusters);
  const clusterColors = useGameStore((state) => state.clusterColors);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const [parent] = useAutoAnimate();
  const [cluster0Name, setCluster0Name] = useState("");
  const [cluster1Name, setCluster1Name] = useState("");
  const [cluster2Name, setCluster2Name] = useState("");
  const [cluster3Name, setCluster3Name] = useState("");
  const [cluster4Name, setCluster4Name] = useState("");
  const [isValidated, setIsValidated] = useState(false);
  const [showHelp, setShowHelp] = useState(true);
  const duplicateOptionSelected = useMemo(
    () =>
      Array.from(
        new Set([
          cluster1Name,
          cluster2Name,
          cluster3Name,
          cluster4Name,
          cluster0Name,
        ])
      ).length !== 5,
    [cluster0Name, cluster1Name, cluster2Name, cluster3Name, cluster4Name]
  );
  const allOptionsSelected = useMemo(
    () =>
      !!cluster0Name &&
      !!cluster1Name &&
      !!cluster2Name &&
      !!cluster3Name &&
      !!cluster4Name,
    [cluster0Name, cluster1Name, cluster2Name, cluster3Name, cluster4Name]
  );
  const correctOptionsSelected = useMemo(
    () =>
      cluster0Name === "Celebrator" &&
      cluster1Name === "Left" &&
      cluster2Name === "Regular" &&
      cluster3Name === "Bingo Night" &&
      cluster4Name === "Special",
    [cluster0Name, cluster1Name, cluster2Name, cluster3Name, cluster4Name]
  );

  const getPreviousYearsCellBackground = (clusterName: string, i: number) => {
    if (!isClustered) {
      if (i === 0) {
        return "bg-[#EAEFFF]";
      } else {
        return "bg-[#C9D7FF]";
      }
    }
    return clusterColors[clusterName];
  };
  const getLastYearCellBackground = (id: Patron["id"]) => {
    if (step2FirstGroupIds.includes(id)) return "bg-color3";
    if (step2SecondGroupIds.includes(id)) return "bg-color4";
    return "bg-color5";
  };
  // eslint-disable-next-line
  const getSelectedCluster = (id: Patron["id"]) => {
    if (!isClustered) return "";
    if (clusters[0].includes(id)) return cluster0Name;
    if (clusters[1].includes(id)) return cluster1Name;
    if (clusters[2].includes(id)) return cluster2Name;
    if (clusters[3].includes(id)) return cluster3Name;
    if (clusters[4].includes(id)) return cluster4Name;
  };

  const validateCluster = (
    selectedClusterName: string,
    correctClusterName: string
  ) => {
    if (!selectedClusterName) return null;
    if (selectedClusterName === correctClusterName)
      return (
        <CheckCircleIcon className="absolute -right-7 -top-[2px] h-6 w-6 text-green-600" />
      );
    return (
      <XCircleIcon className="absolute -right-7 -top-[2px] h-6 w-6 text-red-600" />
    );
  };

  return (
    <div
      className={
        isClustered
          ? `relative mb-2 flex h-full w-2/3 flex-col items-center rounded-[6px] bg-white pb-5`
          : ` relative mb-2 flex h-full w-[55%] flex-col items-center rounded-[6px] bg-white pb-5`
      }
    >
      <h1 className="mt-4 items-center justify-center text-center text-[20px] font-bold text-[#26337C]">
        {!isClustered
          ? "If you have five more columns of data, how would you segment the patrons?"
          : !isValidated
          ? "AI created five patron segments! Can you name the segments?"
          : "Good Job! Click Finish to go to Game 1B"}
      </h1>

      <div className={`flex flex-row text-center`}>
        <div className="flex flex-col">
          <div className="border-black mt-2 flex animate-animateFadeIn flex-row rounded-[6px] border-2  bg-gradient-to-r from-[#C9D7FF] to-[#FFB8C7]">
            <div className="flex h-16 w-[15%] items-center justify-center rounded-l-[6px] font-semibold md:text-[15px] lg:text-[16px] xl:text-[18px]">
              Patrons
            </div>
            <div
              className={`border-black flex h-16 w-[25%] items-center justify-center border-l-2 border-r-2 pl-2 font-semibold md:text-[15px] lg:text-[16px] xl:text-[18px]`}
            >
              Last Friday Sale
            </div>
            <div
              className={`border-black flex h-16 w-[25%] items-center justify-center border-r-2 pl-1 font-semibold md:text-[15px] lg:text-[16px] xl:text-[18px]`}
            >
              Total Last Year Sales
            </div>
            <div
              className={`flex h-16 w-[35%] items-center justify-center font-semibold md:text-[15px] lg:text-[16px] xl:text-[18px] ${
                isClustered ? "rounded-r-[6px]" : "rounded-r-[6px]"
              }`}
            >
              Previous Five Friday Sales
            </div>
            {/* {isClustered ? (
              <div className="flex h-10 w-[20%] items-center justify-center rounded-r-lg text-center font-semibold md:text-[15px] lg:text-[16px] xl:text-[18px]">
                Label
              </div>
            ) : null} */}
          </div>
          <ul
            ref={parent}
            className="mb-10 mt-2 flex w-full animate-animateFadeIn flex-col gap-1"
          >
            {patronList.map((patron) => (
              <div
                key={patron.id}
                className="flex w-full flex-row items-center justify-center rounded-[6px] border border-[#DDE8FF] bg-[#EAEEFF85] text-sm"
              >
                <div className="flex h-[20px] w-[15%] items-center justify-center">
                  {patron.name}
                </div>
                <div
                  className={`flex h-[20px] items-center justify-center ${
                    isClustered ? `w-[25%]` : `w-[25%]`
                  }  ${
                    step1GroupIds.includes(patron.id)
                      ? "bg-color1"
                      : "bg-color2"
                  }`}
                >
                  {patron.actualDaily}
                </div>
                <div
                  className={`flex h-[20px] items-center justify-center ${
                    isClustered ? `w-[25%]` : `w-[25%]`
                  }   ${getLastYearCellBackground(patron.id)}`}
                >
                  {patron.lastYear}
                </div>
                <div
                  className={`flex h-[20px] w-[7%] items-center justify-center  ${getPreviousYearsCellBackground(
                    patron.cluster,
                    0
                  )}`}
                >
                  {patron.previousFriday1}
                </div>
                <div
                  className={`flex h-[20px] w-[7%] items-center justify-center  ${getPreviousYearsCellBackground(
                    patron.cluster,
                    1
                  )}`}
                >
                  {patron.previousFriday2}
                </div>
                <div
                  className={`flex h-[20px] w-[7%] items-center justify-center  ${getPreviousYearsCellBackground(
                    patron.cluster,
                    0
                  )}`}
                >
                  {patron.previousFriday3}
                </div>
                <div
                  className={`flex h-[20px] w-[7%] items-center justify-center  ${getPreviousYearsCellBackground(
                    patron.cluster,
                    1
                  )}`}
                >
                  {patron.previousFriday4}
                </div>
                <div
                  className={`flex h-[20px] w-[7%] items-center justify-center  ${
                    isClustered ? "rounded-r-[6px]" : "rounded-r-[6px]"
                  } ${getPreviousYearsCellBackground(patron.cluster, 0)}`}
                >
                  {patron.previousFriday5}
                </div>
                {/* {isClustered ? (
                  <div
                    className={` flex h-[20px] w-[20%] items-center rounded-r-lg border-l border-gray-600 text-sm
                    ${getPreviousYearsCellBackground(patron.cluster, 1)}`}
                  >
                    {getSelectedCluster(patron.id)}
                  </div>
                ) : null} */}
              </div>
            ))}
          </ul>
        </div>

        {!isClustered ? null : (
          <div className="flex w-40 flex-col">
            <div
              className={`mt-[86px] flex h-[20px] w-[80%] items-center justify-center rounded-[6px] p-2 text-[80%] ${clusterColors["Celebrator"]} relative`}
            >
              <select
                id="select0"
                className={`h-[18px] w-full rounded-[6px] bg-red-100 ps-1`}
                value={cluster0Name}
                onChange={(e) => {
                  setCluster0Name(e.currentTarget.value);
                }}
              >
                <option key="None" value="" disabled>
                  Select a label
                </option>
                {Object.keys(clusterColors).map((clusterName) => (
                  <option key={clusterName} value={clusterName}>
                    {clusterName}
                  </option>
                ))}
              </select>
              {validateCluster(cluster0Name, "Celebrator")}
            </div>

            <div
              className={`mt-[32px] flex h-[20px] w-[80%] items-center justify-center rounded-[6px] p-2 text-[80%] ${clusterColors["Left"]} relative`}
            >
              <select
                id="select1"
                className={`h-[18px] w-full rounded-[6px] bg-yellow-100 ps-1`}
                value={cluster1Name}
                onChange={(e) => {
                  setCluster1Name(e.currentTarget.value);
                }}
              >
                <option key="None" value="" disabled>
                  Select a label
                </option>
                {Object.keys(clusterColors).map((clusterName) => (
                  <option key={clusterName} value={clusterName}>
                    {clusterName}
                  </option>
                ))}
              </select>
              {validateCluster(cluster1Name, "Left")}
            </div>
            <div
              className={`mt-[160px] flex h-[20px] w-[80%] items-center justify-center rounded-[6px] p-2 text-[80%] ${clusterColors["Regular"]} relative`}
            >
              <select
                id="select2"
                className={`h-[18px] w-full rounded-[6px] bg-green-100 ps-1`}
                value={cluster2Name}
                onChange={(e) => {
                  setCluster2Name(e.currentTarget.value);
                }}
              >
                <option key="None" value="" disabled>
                  Select a label
                </option>
                {Object.keys(clusterColors).map((clusterName) => (
                  <option key={clusterName} value={clusterName}>
                    {clusterName}
                  </option>
                ))}
              </select>
              {validateCluster(cluster2Name, "Regular")}
            </div>
            <div
              className={`mt-[163px] flex h-[20px] w-[80%] items-center justify-center rounded-[6px] p-2 text-[80%] ${clusterColors["Bingo Night"]} relative`}
            >
              <select
                id="select3"
                className={`h-[18px] w-full rounded-lg bg-teal-100 ps-1`}
                value={cluster3Name}
                onChange={(e) => {
                  setCluster3Name(e.currentTarget.value);
                }}
              >
                <option key="None" value="" disabled>
                  Select a label
                </option>
                {Object.keys(clusterColors).map((clusterName) => (
                  <option key={clusterName} value={clusterName}>
                    {clusterName}
                  </option>
                ))}
              </select>
              {validateCluster(cluster3Name, "Bingo Night")}
            </div>
            <div
              className={`mt-[32px] flex h-[20px] w-[80%] items-center justify-center rounded-[6px] p-2 text-[80%] ${clusterColors["Special"]} relative`}
            >
              <select
                id="select4"
                className={`h-[18px] w-full justify-center rounded-[6px] bg-purple-100 ps-1`}
                value={cluster4Name}
                onChange={(e) => {
                  setCluster4Name(e.currentTarget.value);
                }}
              >
                <option key="None" value="" disabled>
                  Select a label
                </option>
                {Object.keys(clusterColors).map((clusterName) => (
                  <option key={clusterName} value={clusterName}>
                    {clusterName}
                  </option>
                ))}
              </select>
              {validateCluster(cluster4Name, "Special")}
            </div>
          </div>
        )}
        {isBlurActive && (
          <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
        )}
        {/* {isClustered ? (
          <button
            onClick={(e) => {
              if (duplicateOptionSelected) {
                setIsBlurActive(true);
                toast.error(
                  <>
                    <div className="font-Helvetica text-lg font-bold tracking-wide">Incorrect Labelling</div>
                    <div className="font-Helvetica text-sm tracking-wide">Groups labels should be unique!</div>
                  </>, {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  onClose: () => {
                    setIsBlurActive(false);
                  },
                });
              } else if (!allOptionsSelected || !correctOptionsSelected) {
                setIsBlurActive(true);
                  toast.error(
                    <>
                      <div className="font-Helvetica text-lg font-bold tracking-wide">Incorrect Labelling</div>
                      <div className="font-Helvetica text-sm tracking-wide">Please relabel!</div>
                    </>, {
                    position: "top-center",
                    autoClose: 5000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "light",
                    onClose: () => {
                      setIsBlurActive(false);
                    },
                  }
                );
              } else {
                setIsValidated(true);
                setIsGame1ACompleted(true);
                setIsModalOpen(true);
              }
            }}
            className="fixed bottom-10 right-[10%] flex rounded-md bg-gradient-to-r from-[#3B52F4] to-[#F9477B] px-[30px] py-3 text-base 
            text-white duration-300 ease-in-out hover:-translate-y-1 disabled:cursor-not-allowed disabled:grayscale-[60%] disabled:hover:translate-y-0"
          >
            Finish
          </button>
        ) : null} */}
      </div>
      {isClustered ? (
        showHelp ? (
          <div className="absolute right-[10%] top-[50%] flex h-36 animate-animateFadeIn sm:w-48 sm-max:w-32 md:w-60 lg:w-72 xl:w-80">
            <div className="help absolute right-[0%] flex h-36 w-72 flex-col items-center justify-between gap-0 rounded-[20px] p-3 ipad11-max:h-36">
              <span>
                Select a name that best matches the spend pattern of patrons in
                the segment
              </span>
              <button
                className="button-class rounded-md px-5 py-[2px] text-sm font-semibold text-white underline-offset-2 hover:bg-[#3072D6]"
                onClick={() => {
                  setShowHelp(false);
                }}
              >
                OK
              </button>
            </div>
          </div>
        ) : null
      ) : null}
      {isClustered ? (
        <button
          className="absolute right-2 top-5"
          onClick={() => {
            setShowHelp(true);
          }}
        >
          <InformationCircleIcon className="h-6 w-6 text-[#2B3F6C]" />
        </button>
      ) : null}
      {isModalOpen ? (
        <>
          <div
            className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"
            onClick={() => {
              setIsModalOpen(false);
            }}
          >
            <div
              className="flex flex-col items-center gap-6 rounded-[20px] border-2 border-[#F0B424] bg-[#FFF8EC] px-10 pb-6 pt-8"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="flex flex-row items-start justify-start gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  fill="none"
                  viewBox="0 0 24 24"
                  strokeWidth={1}
                  stroke="#ECB83D"
                  className="h-16 w-20"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M16.5 18.75h-9m9 0a3 3 0 013 3h-15a3 3 0 013-3m9 0v-3.375c0-.621-.503-1.125-1.125-1.125h-.871M7.5 18.75v-3.375c0-.621.504-1.125 1.125-1.125h.872m5.007 0H9.497m5.007 0a7.454 7.454 0 01-.982-3.172M9.497 14.25a7.454 7.454 0 00.981-3.172M5.25 4.236c-.982.143-1.954.317-2.916.52A6.003 6.003 0 007.73 9.728M5.25 4.236V4.5c0 2.108.966 3.99 2.48 5.228M5.25 4.236V2.721C7.456 2.41 9.71 2.25 12 2.25c2.291 0 4.545.16 6.75.47v1.516M7.73 9.728a6.726 6.726 0 002.748 1.35m8.272-6.842V4.5c0 2.108-.966 3.99-2.48 5.228m2.48-5.492a46.32 46.32 0 012.916.52 6.003 6.003 0 01-5.395 4.972m0 0a6.726 6.726 0 01-2.749 1.35m0 0a6.772 6.772 0 01-3.044 0"
                  />
                </svg>
                <div className="flex flex-col gap-2">
                  <span className="text-xl font-semibold">
                    Congratulations!
                  </span>
                  <span className="text-lg  font-medium tracking-wider text-[#2F3F53]">
                    You have completed Game 1A - Segmentation
                  </span>
                </div>
              </div>
              <div className="flex flex-row items-center justify-center gap-3">
                <button
                  className="rounded-md border border-[#979FA9] bg-white px-5 py-[3px] text-lg font-semibold shadow-md hover:bg-[#edeffeac] hover:bg-opacity-10"
                  onClick={(e) => {
                    e.stopPropagation();
                    setIsModalOpen(false);
                  }}
                >
                  BACK
                </button>
                <button
                  className="rounded-md bg-[#F0B424] px-5 py-[3px] text-lg font-semibold text-white shadow-md hover:bg-[#ead787] "
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/game/game1B");
                  }}
                >
                  NEXT
                </button>
              </div>
            </div>
          </div>
          <div className="bg-black fixed inset-0 z-40 opacity-25"></div>
        </>
      ) : null}
      {!isClustered ? (
        <button
          className="button-class absolute bottom-1 mt-2 w-fit rounded-md px-5 py-[3px] text-base text-white shadow-md duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
          onClick={() => {
            clusterPatronList();
            setIsClustered(true);
          }}
        >
          Continue
        </button>
      ) : (
        <button
          // disabled={!allOptionsSelected || !correctOptionsSelected}
          onClick={(e) => {
            if (duplicateOptionSelected) {
              setIsBlurActive(true);
              toast.error(
                <>
                  <div className="font-Helvetica text-lg font-bold tracking-wide">
                    Incorrect Labelling
                  </div>
                  <div className="font-Helvetica text-sm tracking-wide">
                    Groups labels should be unique!
                  </div>
                </>,
                {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  onClose: () => {
                    setIsBlurActive(false);
                  },
                }
              );
            } else if (!allOptionsSelected || !correctOptionsSelected) {
              setIsBlurActive(true);
              toast.error(
                <>
                  <div className="font-Helvetica text-lg font-bold tracking-wide">
                    Incorrect Labelling
                  </div>
                  <div className="font-Helvetica text-sm tracking-wide">
                    Please relabel!
                  </div>
                </>,
                {
                  position: "top-center",
                  autoClose: 5000,
                  hideProgressBar: false,
                  closeOnClick: true,
                  pauseOnHover: true,
                  draggable: true,
                  progress: undefined,
                  theme: "colored",
                  onClose: () => {
                    setIsBlurActive(false);
                  },
                }
              );
            } else {
              setIsValidated(true);
              setIsGame1ACompleted(true);
              setIsModalOpen(true);
            }
          }}
          className="button-class absolute bottom-1 mt-2 flex w-fit rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
        >
          Finish
        </button>
      )}
    </div>
  );
}
