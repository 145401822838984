import {
  getCoreRowModel,
  useReactTable,
  flexRender,
} from "@tanstack/react-table";
import type { ColumnDef } from "@tanstack/react-table";
import { useMemo, useState, useEffect } from "react";
import "./leaderboard.css";
import { FaRedhat, FaTrophy } from "react-icons/fa";
import axiosClient from "../../../config/apiClient";
import { roundOff } from "config/utils";
import { useNavigate } from "react-router-dom";
import { useGameStore } from "../../../store/store";
import { toast } from "react-toastify";
import HistoricalDataComponent from "views/instructor/HistoricalData/historicaldatanew";
/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */
interface LeaderboardData {
  data_dict: {
    total: string;
    team_name: string;
    team_id: number;
    RevenuePeriod1: string;
    RevenuePeriod2: string;
    RevenuePeriod3: string;
    PricePeriod1: string;
    PricePeriod2: string;
    PricePeriod3: string;
    inventory_Period1: string;
    inventory_Period2: string;
    inventory_Period3: string;
    inventory_balance: string;
  }[];
  teamname: string;
  num_teams: number;
}

export const Table = ({
  data,
  columns,
  numberofTeams,
  teamname,
  BlurActive,
  handleResetAll,
}: {
  data: LeaderboardData["data_dict"];
  columns: ColumnDef<LeaderboardData["data_dict"][0]>[];
  numberofTeams: number;
  teamname: string;
  BlurActive: boolean;
  handleResetAll: () => Promise<void>;
}) => {
  let { timeData, authData, game3data } = useGameStore();
  const table = useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
  });
  const maxTotalRevenue = (() => {
    const NotNullTeams = data
      .map((row) => (row.total !== null ? parseFloat(row.total) || null : null))
      .filter((total) => total !== null);
    return NotNullTeams.length > 1 ? Math.max(...NotNullTeams) : 0;
  })();

  const navigate = useNavigate();
  const goToGame3 = () => {
    navigate("/game/game3");
  };

  return (
    <div
      className={`mx-auto -mt-10 flex justify-center px-10 py-5 ${
        authData?.isPlayer
          ? "sm:w-5/12 md:w-5/12 lg:w-6/12 xl:w-7/12 2xl:w-8/12"
          : "sm:w-7/12 md:w-8/12 lg:w-8/12 xl:w-9/12 2xl:w-10/12"
      } `}
    >
      <div className="table-container flex flex-col justify-center">
        <div className="flex flex-col items-center justify-center">
          {game3data &&
          game3data?.time !== timeData[timeData?.length - 1].ui_time ? (
            <div className="flex w-full justify-end">
              <button
                id="continue-leaderboard"
                className="button-class mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
                onClick={goToGame3}
              >
                Continue
              </button>
            </div>
          ) : null}

          {!authData?.isPlayer && data && data[1] ? (
            <div className="flex w-full justify-end">
              <button
                id="resetall-leaderboard"
                className="button-class mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
                onClick={handleResetAll}
              >
                Reset All
              </button>
            </div>
          ) : null}
          <table className="border-slate-400 w-full border-separate border-spacing-2 text-center">
            <thead className="bg-zinc-900 text-16 border-b font-poppins uppercase text-white">
              {table.getHeaderGroups().map((headerGroup) => (
                <tr key={headerGroup.id}>
                  {headerGroup.headers.map((header, index) => (
                    <th
                      key={header.id}
                      className={`font-400 px-4 py-1 text-xl ${
                        index === 0 ? "whitespace-nowrap " : "whitespace-wrap"
                      }`}
                    >
                      {header.isPlaceholder
                        ? null
                        : flexRender(
                            header.column.columnDef.header,
                            header.getContext()
                          )}
                    </th>
                  ))}
                </tr>
              ))}
            </thead>
            <tbody>
              {table.getRowModel().rows.map((row, index) => (
                <tr
                  key={row.id}
                  className={
                    index % 2 === 0
                      ? "bg-zinc-500 h-[7vh] text-[#FAC55F]"
                      : "bg-zinc-200 h-[7vh] text-[#FCF66B]"
                  }
                >
                  {row.getVisibleCells().map((cell, cellIndex) => (
                    <td
                      key={cell.id}
                      className={`text-yellowwt px-3 py-1 text-center font-poppins text-xl capitalize ${
                        cellIndex === 0
                          ? "whitespace-nowrap "
                          : "whitespace-wrap "
                      }`}
                    >
                      <div className="flex flex-col">
                        {cell.column.id === "team_name" && (
                          <div className="flex items-center justify-between text-left">
                            <span className="ml-2">
                              {index + 1} | {row.original.team_name}{" "}
                            </span>
                            {parseFloat(row.original.total) ===
                              maxTotalRevenue &&
                              numberofTeams > 1 &&
                              row.original.PricePeriod1 && (
                                <FaTrophy className="h-7 w-7 text-lime-400" />
                              )}
                            {teamname ? (
                              (row.original.team_name.toLowerCase() ===
                                teamname.toLowerCase() &&
                                (parseFloat(row.original.total) !==
                                  maxTotalRevenue ||
                                  maxTotalRevenue === 0) && (
                                  <FaRedhat className="ml-2 h-7 w-7 text-navy-600" />
                                )) ||
                              (row.original.team_name.toLowerCase() ===
                                teamname.toLowerCase() &&
                                index === 0 &&
                                !row.original.PricePeriod1 && (
                                  <FaRedhat className="ml-2 h-7 w-7 text-navy-600" />
                                ))
                            ) : (
                              <div />
                            )}
                          </div>
                        )}

                        {cell.column.id !== "team_name" && (
                          <div className="item-center flex justify-center">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        )}

                        <div className="align-center flex items-center justify-center text-center">
                          {cell.column.id === "RevenuePeriod1" && (
                            <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                              <div
                                className={`price-hexagon price-color h-[6%] w-10 ${
                                  cellIndex === 0 ? "text-left" : "text-center"
                                }`}
                              >
                                <div className="font-small mr-2 text-xs text-white">
                                  {row.original.PricePeriod1
                                    ? `$${row.original.PricePeriod1}`
                                    : null}
                                </div>
                              </div>
                              <div
                                className={`inventory inventory-color h-[6%] w-10 text-center`}
                              >
                                <div className="font-small text-xs  text-white">
                                  {row.original.inventory_Period1
                                    ? row.original.inventory_Period1
                                    : null}
                                </div>
                              </div>
                            </div>
                          )}
                          {cell.column.id === "RevenuePeriod2" && (
                            <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                              <div className="price-hexagon price-color h-[6%] w-10 text-center">
                                <div className="font-small mr-2 text-xs text-white">
                                  {row.original.PricePeriod2
                                    ? `$${row.original.PricePeriod2}`
                                    : null}
                                </div>
                              </div>
                              <div
                                className={`inventory inventory-color h-[6%] w-10 text-center`}
                              >
                                <div className="font-small text-xs  text-white">
                                  {row.original.inventory_Period2
                                    ? row.original.inventory_Period2
                                    : null}
                                </div>
                              </div>
                            </div>
                          )}
                          {cell.column.id === "RevenuePeriod3" && (
                            <div className="align-center flex items-center justify-between sm:w-11/12 md:w-11/12 lg:w-11/12 xl:w-10/12 2xl:w-9/12">
                              <div className="price-hexagon price-color h-[6%] w-10 text-center">
                                <div className="font-small mr-2 text-xs text-white">
                                  {row.original.PricePeriod3
                                    ? `$${row.original.PricePeriod3}`
                                    : null}
                                </div>
                              </div>
                              <div
                                className={`inventory inventory-color h-[6%] w-10 text-center`}
                              >
                                <div className="font-small text-xs  text-white">
                                  {row.original.inventory_Period3
                                    ? row.original.inventory_Period3
                                    : null}
                                </div>
                              </div>
                            </div>
                          )}
                          {cell.column.id === "total" && (
                            <div
                              className={`inventory total-inventory-color h-[6%] w-10 text-center`}
                            >
                              <div className="font-small text-xs  text-white">
                                {parseInt(row.original.inventory_balance) >= 0
                                  ? row.original.inventory_balance
                                  : null}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      {BlurActive && (
        <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
      )}
    </div>
  );
};
const Leaderboard = () => {
  let { authData } = useGameStore();
  let allColumns;
  const [Loading, setLoading] = useState(false);
  const [viewhistory, setviewhistory] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  interface HistoricalData {
    id: number;
    user_id: number;
    username: string;
    team_id: number;
    teamname: string;
    pe_id: number;
    price: number;
    period: string;
    sale: number;
    revenue: number;
    cumulative_revenue: number;
    beginning_inventory: number;
    ending_inventory: number;
    date: string;
    created_at: string;
  }
  const [historydata, setHistoryData] = useState<HistoricalData[]>([]);
  const [data, setData] = useState<LeaderboardData>({
    data_dict: [],
    teamname: "",
    num_teams: 0,
  });

  const getleaderboard = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.post(`game3/getleaderboard`, {
        time_periods: ["Period 1", "Period 2", "Period 3"],
      });
      setLoading(false);
      let updatedData = response.data;
      updatedData.data_dict = response?.data?.data_dict.map((obj) => {
        let totalRevenue = 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod1)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod2)) || 0;
        totalRevenue += roundOff(parseFloat(obj?.RevenuePeriod3)) || 0;
        obj.total = totalRevenue;
        return obj;
      });
      setData(updatedData);
    } catch (error) {
      console.error("Error sending data to the server:", error);
      setLoading(false);
      return;
    }
  };
  useEffect(() => {
    getleaderboard();
  }, []);

  const addPrefixAndRoundOff = (value: any) => {
    if (value === 0 || value === null) return;
    return `$${roundOff(value)}`;
  };

  const cols = useMemo<ColumnDef<LeaderboardData["data_dict"][0]>[]>(
    () => [
      {
        header: "Team Name",
        cell: (row) => row.renderValue(),
        accessorKey: "team_name",
      },
      {
        header: "Period 1 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod1",
      },
      {
        header: "Period 2 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod2",
      },
      {
        header: "Period 3 Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "RevenuePeriod3",
      },
      {
        header: "Total Revenue",
        cell: (row: any) => addPrefixAndRoundOff(row.renderValue()),
        accessorKey: "total",
      },
    ],
    []
  );
  if (!authData?.isPlayer) {
    const buttonColumns: ColumnDef<LeaderboardData["data_dict"][0]>[] = [
      {
        accessorKey: "Reset",
        header: "RESET",
        cell: ({ row }) => (
          <button
            id="reset-leaderboard"
            className="button-class mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
            onClick={() => getReset(row.original)}
          >
            Reset
          </button>
        ),
      },
      {
        accessorKey: "History",
        header: "History",
        cell: ({ row }) => (
          <button
            id="history-leaderboard"
            className="button-class mb-2 flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
            onClick={() => getHistoricalData(row.original)}
          >
            History
          </button>
        ),
      },
    ];

    allColumns = [...cols, ...buttonColumns];
  }
  const getReset = async (rowData: LeaderboardData["data_dict"][0]) => {
    try {
      setLoading(true);
      const response = await axiosClient.post(`game3/getreset`, {
        team: rowData.team_name,
      });

      setLoading(false);
      toast.success(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Reset Successful
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Team "{rowData.team_name}" reset Successful
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      getleaderboard();
      return response.data;
    } catch (error) {
      setLoading(false);
      if (rowData.team_name !== "") {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Data Not Found
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Reset for the "{rowData.team_name}" unsuccessful
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
      }
      console.error("Error sending data to the server:", error);
    }
  };
  const getHistoricalData = async (
    rowData: LeaderboardData["data_dict"][0]
  ) => {
    try {
      setLoading(true);
      const response = await axiosClient.post(`game3/gethistoricaldata`, {
        team: rowData.team_name,
      });
      setLoading(false);
      setHistoryData(response.data);
      setviewhistory(true);
      return response.data;
    } catch (error) {
      setLoading(false);
      if (rowData.team_name !== "") {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Data Not Found
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Historical data for "{rowData.team_name}" does not exist
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
      }
      console.error("Error sending data to the server:", error);
    }
  };
  const handleBackClick = () => {
    setviewhistory(false);
    setHistoryData([]);
  };
  const handleResetAll = async () => {
    try {
      setLoading(true);
      const response = await axiosClient.delete(`game3/resetAllTeam`);
      setLoading(false);
      toast.success(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Reset Successful
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Reset Successful for all teams
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      getleaderboard();
      return response.data;
    } catch (error) {
      setLoading(false);
      setIsBlurActive(true);
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Error
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Reset unsuccessful
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      console.error("Error sending data to the server:", error);
    }
  };
  return (
    <>
      <div className="mt-20 min-h-screen lg-max:hidden">
        {Loading ? (
          <div className="flex items-center justify-center">
            <div className="h-10 w-10 animate-spin rounded-full border-t-2 border-blue-600"></div>
          </div>
        ) : !viewhistory ? (
          <Table
            data={data.data_dict}
            columns={authData?.isPlayer ? cols : allColumns}
            teamname={data.teamname}
            numberofTeams={data.num_teams}
            BlurActive={isBlurActive}
            handleResetAll={handleResetAll}
          />
        ) : viewhistory ? (
          <>
            <HistoricalDataComponent
              historicaldata={historydata}
              handleBackClick={handleBackClick}
              
            ></HistoricalDataComponent>
          </>
        ) : null}
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
export default Leaderboard;
