import { StateCreator } from "zustand";

export interface Game1BSlice {
  isGame1BCompleted: boolean;
  setIsGame1BCompleted: (completed: boolean) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createGame1BSlice: ImmerStateCreator<Game1BSlice> = (set) => ({
  isGame1BCompleted: false,
  setIsGame1BCompleted: (completed) =>
    set((state) => {
      state.isGame1BCompleted = completed;
    }),
});
