import "./CustomLineChart.css";
import {
  removeSlidderAnnotation,
  createLabel,
  SetColorandLabel,
  computeNearestVal,
} from "../charts/chartUtlis";
import Highcharts from "highcharts";
import { useEffect, useState, useRef } from "react";
import HighchartsAnnotations from "highcharts/modules/annotations.js";
import {
  IoIosArrowDropleftCircle,
  IoIosArrowDroprightCircle,
} from "react-icons/io";
import {
  HelpDialog,
  ContinueDialog,
  ShowCorrectAnswerDialog,
  CongratsDialog,
} from "./chartDialogBox";
HighchartsAnnotations(Highcharts);

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */

interface LineChartProps {
  data: {
    purchase: number[];
    values: {
      profit: number;
      cost: number;
      exp_revenue: number;
    }[];
    peak: number;
  };
}
const LineChart = (props: LineChartProps) => {
  let { data } = props;
  const chartRef = useRef<any>(null);
  const [XPosition, setXAnnotation] = useState(0);
  const [trails, setTrails] = useState(1);
  const [refreshChart, setRefreshChart] = useState(false);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showAnswer, showCorrectAnswer] = useState(false);
  const [showHelp, setShowHelp] = useState(false);
  const [isGameCompletedModalOpen, setIsGameCompletedModalOpen] =
    useState(false);
  let circleshape,
    lineshape,
    circleFill,
    lineStroke,
    LabelPoint,
    LabelPointDataPointIndex,
    LabelPointDataPoint,
    restartButton,
    newIndex;
  if (data === null || data === undefined) {
    data = {
      purchase: [0, 40, 80, 120, 160, 200, 240, 280],
      values: [],
      peak: 0,
    };
  }
  const allValues = data.values
    .map((item) => [item.cost, item.exp_revenue, item.profit])
    .flat();
  const maxYValue = Math.max(...allValues);
  const maxinterval = maxYValue / (4 - 2);
  const matchingProfitItem = data.values.find(
    (item) => item.profit === data.peak
  );
  let matchingProfit: number | null = null;
  let matchingProfitIndex: number = -1;
  if (matchingProfitItem) {
    matchingProfit = matchingProfitItem.profit;
    matchingProfitIndex = data.values.indexOf(matchingProfitItem);
  }
  const xCoordinate = data.purchase[matchingProfitIndex];
  const xCoordinateDataPointIndex = data.purchase.indexOf(xCoordinate);
  const xCoordinateDataPoint = data.values[xCoordinateDataPointIndex];
  let xAnnotation = 0;
  let functiondata: any = {
    xCoordinate,
    circleshape,
    lineshape,
    chartRef,
    data,
    xAnnotation,
    maxinterval,
    LabelPoint,
    LabelPointDataPointIndex,
    LabelPointDataPoint,
  };
  functiondata.data = data;
  functiondata.chartRef = chartRef;
  const updateShapes = (
    chart,
    annotationShapes,
    newIndex,
    xAnnotation,
    DataPoint
  ) => {
    const circleshape = annotationShapes[0];
    const lineshape = annotationShapes[1];

    const updatePoint = (point, x, y) => {
      (point as any).x = x;
      (point as any).y = y;
    };
    updatePoint(lineshape.points[0], xAnnotation, 20);
    updatePoint(lineshape.points[1], xAnnotation, 2000);
    updatePoint(circleshape.points[0], xAnnotation, 16);
    updatePoint(circleshape.points[1], xAnnotation, 1000);
    functiondata.xAnnotation = xAnnotation;
    functiondata.LabelPoint = xAnnotation;
    functiondata.LabelPointDataPointIndex = newIndex;
    functiondata.LabelPointDataPoint = DataPoint;
    SetColorandLabel(functiondata);
    chart.redraw();
  };
  const moveAnnotation = (direction) => {
    const chart = chartRef.current;
    const annotationShapes = chart.options.annotations[0].shapes;
    const maxIndex = data.purchase.length - 1;
    if (direction === "left") {
      newIndex = XPosition > 0 ? XPosition - 1 : 0;
    } else if (direction === "right") {
      newIndex = XPosition < maxIndex ? XPosition + 1 : 0;
    }
    const xAnnotation = data.purchase[newIndex];
    setXAnnotation(newIndex);
    const DataPoint = data.values[newIndex];
    updateShapes(chart, annotationShapes, newIndex, xAnnotation, DataPoint);
  };
  const moveAnnotationLeft = () => {
    moveAnnotation("left");
  };
  const moveAnnotationRight = () => {
    moveAnnotation("right");
  };
  if (showAnswer) {
    let chart = chartRef.current;
    removeSlidderAnnotation(functiondata, "myslidder");
    removeSlidderAnnotation(functiondata, "tooltip");
    chart.update({
      xAxis: {
        plotLines: [
          {
            color: "green",
            width: 2,
            value: xCoordinate,
            label: {
              text: "<h5 style='background-color: rgb(194, 233, 157);padding:0.313rem'> Correct Answer </h5>",
              useHTML: true,
              align: "center",
              rotation: 0,
              top: -1,
              padding: 5,
              opacity: 10,
              zIndex: 3,
              style: {
                color: "green",
                fontWeight: "bold",
              },
            },
          },
        ],
      },
      series: [
        {
          dataLabels: {
            enabled: false,
          },
        },
      ],
    });
    functiondata.xAnnotation = xAnnotation;
    functiondata.LabelPoint = xCoordinate;
    functiondata.LabelPointDataPointIndex = xCoordinateDataPointIndex;
    functiondata.LabelPointDataPoint = xCoordinateDataPoint;
    createLabel(functiondata);
    var restartBtn = document.getElementById("restartBtn");
    if (restartBtn) {
      restartBtn.style.display = "none";
    }
    var styles = {
      fill: "#0c7cc2",
      padding: 7,
      "stroke-width": 1,
      stroke: "#1d47b0",
      r: 6,
      style: {
        color: "#FFFFFF",
        fontWeight: "bold",
        fontSize: "0.9rem",
        letterSpacing: "0.063rem",
      },
    };
    var callback = function () {
      setIsGameCompletedModalOpen(true);
      showCorrectAnswer(false);

    };
    var continueBtn = chart.renderer
      .button(
        "Continue",
        chart.chartWidth - 100,
        2,
        callback,
        styles,
        styles,
        styles,
        styles
      )
      .attr({
        id: "continueBtn",
      })
      .css({})
      .add();
    chart.redraw();
  }
  useEffect(() => {
    try {
      let xtouch, mySlidder;
      const chartOptions: Highcharts.Options = {
        chart: {
          type: "line",
          renderTo: "container",
          spacingTop: 10,
          spacingBottom: -10,
        },
        legend: {
          align: "right",
          margin: 0,
          itemMarginBottom: 8,
        },
        credits: {
          enabled: false,
        },
        title: {
          text: "Profit Calculation",
          align: "center",
          margin: 3,
          style: {
            color: "#777",
            fontWeight: "600",
            fontSize: "1.3rem",
            letterSpacing: "0.063rem",
          },
        },
        accessibility: {
          enabled: false,
        },
        xAxis: {
          type: "linear",
          // top: '20%',
          zoomEnabled: true,
          title: {
            text: "Peak Profit",
            align: "middle",
            style: {
              color: "#414141",
              fontWeight: "600",
              fontSize: "1.025rem",
              letterSpacing: "0.063rem",
            },
          },
          labels: {
            style: {
              color: "grey",
              fontFamily: "Roboto",
              fontSize: "1.025rem",
              fontWeight: "700",
              letterSpacing: "0.063rem",
            },
          },
          gridLineWidth: 0,
          tickInterval: 40,
          tickWidth: 0.5,
          lineWidth: 0.5,
          lineColor: "#949494",
          tickColor: "#949494",
          minPadding: 0.1,
          maxPadding: 0.1,
        },
        yAxis: {
          //min: minYValue - maxinterval,
          max: maxYValue + maxinterval,
          startOnTick: true,
          tickColor: "#949494",
          tickWidth: 0.5,
          tickAmount: 4,
          lineWidth: 0.5,
          lineColor: "#949494",
          gridLineWidth: 0.8,
          title: {
            text: "Optimal Pint Buy",
            style: {
              color: "#414141",
              fontWeight: "600",
              fontSize: "1.025rem",
              letterSpacing: "0.063rem",
            },
          },
          labels: {
            formatter: function (
              this: Highcharts.AxisLabelsFormatterContextObject
            ) {
              if (this.value && typeof this.value === "number") {
                return this.value.toString();
              } else if (this.value === 0) {
                return this.value.toString();
              }
              return "";
            },
            style: {
              color: "grey",
              fontFamily: "Roboto",
              fontSize: "0.875rem",
              fontWeight: "700",
              letterSpacing: "0.063rem",
            },
          },
        },
        series: [
          {
            name: "Profit",
            type: "line",
            data: data.values.map((item) => [
              data.purchase[data.values.indexOf(item)],
              item.profit,
            ]),
            dataLabels: {
              enabled: true,
              useHTML: true,

              style: {
                fontSize: "0.975rem",
                letterSpacing: "0.063rem",
                fontWeight: "400",
                textAlign: "center",
              },
              zIndex: 10,
              format: "$ {y}",
              backgroundColor: "white",
              y: -6,
              borderWidth: 1,
              borderColor: "#AAA",
              borderRadius: 5,
              align: "right",
              crop: true,
            },
            enableMouseTracking: false,
            color: "grey",
            marker: {
              enabled: true,
            },
            lineWidth: 3,
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          {
            name: "Cost",
            type: "line",
            data: data.values.map((item) => [
              data.purchase[data.values.indexOf(item)],
              item.cost,
            ]),
            marker: {
              enabled: false,
            },
            lineWidth: 3,
            color: "red",
            animation: false,
            enableMouseTracking: false,
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
          {
            name: "Revenue",
            type: "line",
            data: data.values.map((item) => [
              data.purchase[data.values.indexOf(item)],
              item.exp_revenue,
            ]),
            marker: {
              enabled: false,
            },
            lineWidth: 3,
            color: "green",
            animation: false,
            enableMouseTracking: false,
            states: {
              inactive: {
                opacity: 1,
              },
            },
          },
        ],
      };
      const chart: any = new Highcharts.Chart(chartOptions);
      chartRef.current = chart;
      const Slidderannotation = {
        draggable: "x",
        id: "myslidder",
        zIndex: 999,
        shapes: [
          {
            type: "circle",
            fill: circleFill ? circleFill : "#D9D9D9",
            r: 15,
            points: [
              {
                x: 0,
                y: 16,
                xAxis: 0,
                yAxis: 1000,
              },
              {
                x: 0,
                y: 2000,
                xAxis: 0,
                yAxis: 1000,
              },
            ],
          },
          {
            type: "path",
            strokeWidth: 5,
            stroke: lineStroke ? lineStroke : "#D9D9D9",
            points: [
              {
                x: 0,
                y: 20,
                xAxis: 0,
                yAxis: 1000,
              },
              {
                x: 0,
                y: 2000,
                xAxis: 0,
                yAxis: 1000,
              },
            ],
          },
        ],
        events: {
          afterUpdate: function (this: Highcharts.Annotation) {
            const annotationOptions = this.options;

            if (annotationOptions.shapes) {
              const [circleshape, lineshape] = annotationOptions.shapes;
              const point = circleshape.points[0];

              let xAnnotation = Math.round((point as any).x);
              functiondata.xAnnotation = xAnnotation;
              functiondata.lineshape = lineshape;
              functiondata.circleshape = circleshape;
              xAnnotation = computeNearestVal(functiondata);
              const xAnnotateDataPointIndex =
                data.purchase.indexOf(xAnnotation);
              const DataPoint = data.values[xAnnotateDataPointIndex];
              functiondata.xAnnotation = xAnnotation;
              functiondata.LabelPoint = xAnnotation;
              functiondata.LabelPointDataPointIndex = xAnnotateDataPointIndex;
              functiondata.LabelPointDataPoint = DataPoint;
              SetColorandLabel(functiondata);
              setXAnnotation(xAnnotateDataPointIndex);
              if (chart) {
                chart.redraw();
              }
            } else {
              if (this.chart) {
                this.chart.redraw();
              }
            }
          },
        },
      };
      chart.addAnnotation(Slidderannotation);
      if (data) {
        const xAnnotateDataPointIndex = data.purchase.indexOf(xAnnotation);
        if (
          xAnnotateDataPointIndex >= 0 &&
          data.values[xAnnotateDataPointIndex]
        ) {
          const DataPoint = data.values[xAnnotateDataPointIndex];
          functiondata.xAnnotation = xAnnotation;
          functiondata.LabelPoint = xAnnotation;
          functiondata.LabelPointDataPointIndex = xAnnotateDataPointIndex;
          functiondata.LabelPointDataPoint = DataPoint;
          createLabel(functiondata);
        }
      }
      var element = chart.annotations[0].shapesGroup.element;
      if (element) {
        const pathElements = element.querySelectorAll("path");
        if (pathElements.length >= 2) {
          const secondPathElement = pathElements[1];
          secondPathElement.addEventListener(
            "touchstart",
            handleAnnotationTouch
          );
          secondPathElement.addEventListener("touchmove", handleAnnotationDrag);
        }
        const circleElement = element.querySelector("circle");
        circleElement.addEventListener("click", handleAnnotationClick);
        circleElement.addEventListener("touchstart", handleAnnotationClick);
      }
      function handleAnnotationTouch(event) {
        event.preventDefault();
        event.stopPropagation();
        xtouch = chart.xAxis[0].toValue(event.touches[0].clientX);
        mySlidder = chart.annotations.find(
          (annotation) => annotation.options.id === "myslidder"
        );
        circleshape = mySlidder.options.shapes[0];
        lineshape = mySlidder.options.shapes[1];
        functiondata.lineshape = lineshape;
        functiondata.circleshape = circleshape;
        xAnnotation = xtouch;
      }
      function handleAnnotationDrag(event) {
        event.preventDefault();
        event.stopPropagation();
        xtouch = chart.xAxis[0].toValue(event.touches[0].clientX);
        functiondata.xAnnotation = xtouch;
        xAnnotation = computeNearestVal(functiondata);
        const xAnnotateDataPointIndex = data.purchase.indexOf(xAnnotation);
        const DataPoint = data.values[xAnnotateDataPointIndex];
        functiondata.xAnnotation = xtouch;
        functiondata.LabelPoint = xAnnotation;
        functiondata.LabelPointDataPointIndex = xAnnotateDataPointIndex;
        functiondata.LabelPointDataPoint = DataPoint;
        functiondata.XPosition = xAnnotateDataPointIndex;
        SetColorandLabel(functiondata);
        setXAnnotation(xAnnotateDataPointIndex);
      }
      function handleAnnotationClick(event) {
        event.preventDefault();
        const clickedShape = event.target;
        if (clickedShape && clickedShape.tagName === "circle") {
          const annotationOptions = chart.annotations[0];
          if (annotationOptions.shapes) {
            const circleshape = annotationOptions.shapes[0];
            const point = circleshape.points[0];
            xAnnotation = (point as any).x;
            xAnnotation = Math.round(xAnnotation);
            const difference = Math.abs(xAnnotation - xCoordinate);
            if (difference <= 1) {
              removeSlidderAnnotation(functiondata, "myslidder");
              removeSlidderAnnotation(functiondata, "tooltip");
              showCorrectAnswer(true);
            } else {
              if (trails === 4) {
                setIsModalOpen(true);
              }
              if (trails === 1 || trails === 2) {
                setShowHelp(true);
              } else {
                setShowHelp(false);
              }
              var styles = {
                fill: "#0c7cc2",
                "stroke-width": 1,
                stroke: "#027ac4",
                r: 6,
                style: {
                  color: "#FFFFFF",
                  fontWeight: "bold",
                  fontSize: "1rem",
                  letterSpacing: "0.063rem",
                },
              };
              restartButton = chart.renderer
                .button(
                  "Retry",
                  chart.chartWidth - 100,
                  2,
                  styles,
                  styles,
                  styles,
                  styles
                )
                .attr({
                  id: "retry-Btn",
                })
                .on("click", function () {
                  setTrails((prev) => prev + 1);
                  setShowHelp(false);
                  setXAnnotation(data.purchase[0]);
                  functiondata.xAnnotation = xtouch;
                  functiondata.XPosition = data.purchase[0];
                  setRefreshChart((prev) => !prev);
                })
                .add();
              restartButton.element.innerHTML += `
                  <svg x="52" y="3" width="25" height="25" viewBox="-2.16 -4.16 28.32 28.32" fill="none" xmlns="http://www.w3.org/2000/svg"
                  transform="rotate(45)matrix(-1, 0, 0, 1, 0, 0)" stroke="#ffffff" stroke-width="0.624">
                  <g id="SVGRepo_bgCarrier" stroke-width="0" transform="translate(0,0), scale(1)">
                  <rect x="-2.16" y="-2.16" width="28.32" height="28.32" rx="14.16" fill=" #0c7cc2" strokewidth="0"></rect></g>
                  <g id="SVGRepo_tracerCarrier" stroke-linecap="round" stroke-linejoin="round" stroke="#CCCCCC" stroke-width="0.096"></g>
                  <g id="SVGRepo_iconCarrier"> <path d="M12 20.75C10.078 20.7474 8.23546 19.9827 6.8764 18.6236C5.51733 17.2645 4.75265 15.422 4.75 13.5C4.75 13.3011 4.82902 13.1103 4.96967 12.9697C5.11032 12.829 5.30109 12.75 5.5 12.75C5.69891 12.75 5.88968 12.829 6.03033 12.9697C6.17098 13.1103 6.25 13.3011 6.25 13.5C6.25 14.6372 6.58723 15.7489 7.21905 16.6945C7.85087 17.6401 8.74889 18.3771 9.79957 18.8123C10.8502 19.2475 12.0064 19.3614 13.1218 19.1395C14.2372 18.9177 15.2617 18.37 16.0659 17.5659C16.87 16.7617 17.4177 15.7372 17.6395 14.6218C17.8614 13.5064 17.7475 12.3502 17.3123 11.2996C16.8771 10.2489 16.1401 9.35087 15.1945 8.71905C14.2489 8.08723 13.1372 7.75 12 7.75H9.5C9.30109 7.75 9.11032 7.67098 8.96967 7.53033C8.82902 7.38968 8.75 7.19891 8.75 7C8.75 6.80109 8.82902 6.61032 8.96967 6.46967C9.11032 6.32902 9.30109 6.25 9.5 6.25H12C13.9228 6.25 15.7669 7.01384 17.1265 8.37348C18.4862 9.73311 19.25 11.5772 19.25 13.5C19.25 15.4228 18.4862 17.2669 17.1265 18.6265C15.7669 19.9862 13.9228 20.75 12 20.75Z" fill="#ffffff"></path> <path d="M12 10.75C11.9015 10.7505 11.8038 10.7313 11.7128 10.6935C11.6218 10.6557 11.5392 10.6001 11.47 10.53L8.47 7.53003C8.32955 7.38941 8.25066 7.19878 8.25066 7.00003C8.25066 6.80128 8.32955 6.61066 8.47 6.47003L11.47 3.47003C11.5387 3.39634 11.6215 3.33724 11.7135 3.29625C11.8055 3.25526 11.9048 3.23322 12.0055 3.23144C12.1062 3.22966 12.2062 3.24819 12.2996 3.28591C12.393 3.32363 12.4778 3.37977 12.549 3.45099C12.6203 3.52221 12.6764 3.60705 12.7141 3.70043C12.7518 3.79382 12.7704 3.89385 12.7686 3.99455C12.7668 4.09526 12.7448 4.19457 12.7038 4.28657C12.6628 4.37857 12.6037 4.46137 12.53 4.53003L10.06 7.00003L12.53 9.47003C12.6704 9.61066 12.7493 9.80128 12.7493 10C12.7493 10.1988 12.6704 10.3894 12.53 10.53C12.4608 10.6001 12.3782 10.6557 12.2872 10.6935C12.1962 10.7313 12.0985 10.7505 12 10.75Z" fill="#ffffff"></path> </g></svg>`;
              var rectElement = document.querySelector(
                "#retry-Btn .highcharts-button-box "
              );
              rectElement.setAttribute("width", "84");
              rectElement.setAttribute("height", "33");
            }
            let wrongAnswer: number = xAnnotation;
            const wrongAnswerDataPointIndex =
              data.purchase.indexOf(wrongAnswer);
            const wrongAnswerDataPoint = data.values[wrongAnswerDataPointIndex];
            chart.removeAnnotation("myslidder");
            chart.update({
              xAxis: {
                plotLines: [
                  {
                    color: "#FF0000",
                    width: 2,
                    value: wrongAnswer,
                    label: {
                      text: "<h5 style='background-color: #FCB6AC;padding:0.313rem'>Wrong Answer!</h5>",
                      useHTML: true,
                      align: "center",
                      rotation: 0,
                      opacity: 10,
                      zIndex: 3,
                      padding: 5,
                      style: {
                        color: "#FF0000",
                        fontWeight: "bold",
                      },
                    },
                  },
                ],
              },
              series: [
                {
                  dataLabels: {
                    enabled: false,
                  },
                },
              ],
            });
            removeSlidderAnnotation(functiondata, "tooltip");
            functiondata.xAnnotation = xAnnotation;
            functiondata.LabelPoint = wrongAnswer;
            functiondata.LabelPointDataPointIndex = wrongAnswerDataPointIndex;
            functiondata.LabelPointDataPoint = wrongAnswerDataPoint;
            createLabel(functiondata);
          }
        }
      }
      return () => {
        chart?.destroy();
      };
    } catch (error) {
      console.error("Highcharts error:", error);
    }
  }, [props.data, refreshChart]);
  return (
    <>
      <div className="absolute flex h-[100%] w-[100%] flex-row items-center justify-center">
        {props.data && (
          <button
            className="-ml-10 "
            onClick={moveAnnotationLeft}
            id="left-Btn"
            style={{ color: "white", fontSize: "24px" }}
          >
            <IoIosArrowDropleftCircle size={40} />
          </button>
        )}
        <div
          id="container"
          style={{
            textAlign: "center",
            width: "100%",
          }}
        ></div>
        {isModalOpen && (
          <ShowCorrectAnswerDialog
            setIsModalOpen={setIsModalOpen}
            trails={trails}
            setTrails={setTrails}
            showCorrectAnswer={showCorrectAnswer}
          />
        )}
        {isGameCompletedModalOpen && (
          <CongratsDialog
            setIsGameCompletedModalOpen={setIsGameCompletedModalOpen}
          />
        )}
        {showHelp && <HelpDialog trails={trails} setShowHelp={setShowHelp} />}
        {showAnswer && <ContinueDialog showCorrectAnswer={showCorrectAnswer} />}
        {props.data && (
          <button
            className="-mr-10"
            onClick={moveAnnotationRight}
            id="right-Btn"
            style={{ color: "white", fontSize: "24px" }}
          >
            <IoIosArrowDroprightCircle size={40} />
          </button>
        )}
      </div>
    </>
  );
};
export default LineChart;
