import { StateCreator } from "zustand";

// Define the type for your server response
export interface ServerResponse {
  Purchase: number[];
  probability: number[];
  chart_name: string;
}
export interface LineChartData {
  purchase: number[];
  values: {
    profit: number;
    cost: number;
    exp_revenue: number;
  }[];
  peak: number;
}

export interface Game2Slice {
  barChartData: ServerResponse | null; // Initialize as null
  setBarChartData: (data: ServerResponse | null) => void;
  lineChartData: LineChartData | null; // Initialize as null
  setLineChartData: (data: LineChartData | null) => void;
  Game2reset: () => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createGame2Slice: ImmerStateCreator<Game2Slice> = (set) => ({
  barChartData: null, // Initialize as null
  setBarChartData: (data) =>
    set((state) => {
      state.barChartData = data;
    }),
  lineChartData: null, // Initialize as null
  setLineChartData: (data) =>
    set((state) => {
      state.lineChartData = data;
    }),
  Game2reset: () => {
    set((state) => {
      state.lineChartData = null;
      state.barChartData = null;
      localStorage.removeItem("radio");
      localStorage.removeItem("pintBuy");
      localStorage.removeItem("pintSell");
    });
  },
});
