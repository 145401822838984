import React from "react";
import Dropdown from "components/dropdown";
import { FaSignOutAlt } from "react-icons/fa";
import { FaUser, FaUsers } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../config/AuthDataProvider";
import { useGameStore } from "store/store";
import { usePusher } from "context/pusher/PusherContext";

// import axiosClient from "config/apiClient";
import axios from "axios";
import { getEnvironment } from "../../config/environment";

const env = getEnvironment();

function CardMenu(props: { transparent?: boolean }) {
  const { transparent } = props;
  const DataAuth = useAuth();
  const [open, setOpen] = React.useState(false);
  const navigate = useNavigate();
  const thisPusher = usePusher();

  const { Game2reset, setauthData, Game3reset, Game1reset, authData } =
    useGameStore();
  let username, teamname;
  if ((DataAuth && DataAuth?.username) || DataAuth?.teamname) {
    username = DataAuth?.username;
    teamname = DataAuth?.teamname;
  }
  function clearData() {
    localStorage.removeItem(`user_${authData.username}_price`);
    thisPusher.disconnect()
    setauthData(null);
    Game1reset();
    Game2reset();
    Game3reset();
    navigate("/auth/login");
  }

  const redirect = async () => {
    await axios
      .post(`${env.endpoint}/tenant/logout/`, { refresh: authData.refresh })
      .then(() => {
        clearData();
      })
      .catch(() => {
        clearData();
      });
  };
  return (
    <Dropdown
      button={
        <button
          onClick={() => setOpen(!open)}
          id="profile-icon"
          className={`flex items-center  text-sm hover:cursor-pointer ${
            transparent
              ? "bg-none text-white hover:bg-none active:bg-none"
              : " bg-none p-2 text-blue-800  dark:bg-navy-700 dark:text-white dark:hover:bg-white/20 "
          } linear justify-center rounded-md font-bold transition duration-300`}
        >
          <FaUser className="h-[2.2vw] w-[2.2vw]" />
        </button>
      }
      animation={
        "z-10 origin-top-right transition-all duration-300 ease-in-out"
      }
      classNames={`${transparent ? "top-9" : "top-12"} -right-20 w-max`}
      children={
        <div className="z-50 w-52 rounded-md border-solid border-blue-800 bg-white text-sm shadow-xl">
          <p className=" flex items-center gap-10 px-4 py-1 text-lg capitalize text-blue-800">
            <span>
              <FaUser />
            </span>
            {username}
          </p>
          {teamname ? (
            <p className="mt-1 flex items-center gap-10 px-4 py-1  text-lg capitalize text-blue-800">
              <span>
                <FaUsers />
              </span>
              {teamname}
            </p>
          ) : (
            <p />
          )}
          <button
            onClick={redirect}
            id="logout"
            className="flex w-full cursor-pointer items-center gap-10 rounded-b-md bg-gradient-to-br from-[#279DF3] to-[#0466AD] px-4 py-1 text-lg font-black text-white"
          >
            <span>
              <FaSignOutAlt />
            </span>
            Logout
          </button>
        </div>
      }
    />
  );
}

export default CardMenu;
