import React, { useState, useEffect } from "react";
import { MultiSelect } from "react-multi-select-component";
import "../components/multiselect.css";
import axiosClient from "../../../../config/apiClient";

const MultiSelectDropdown = ({ onSelect }) => {
  const [Team, setTeam] = useState([]);
  const getAllTeams = async () => {
    try {
      const response = await axiosClient.get(`game3/getallteams`);
      setTeam(response.data.teams);
    } catch (error) {
      console.error("Error sending data to the server:", error);
    }
  };
  useEffect(() => {
    getAllTeams();
  }, []);


  const options = Team.map((teamname) => ({
    label: teamname,
    value: teamname
  }));

  const [selectedTeams, setSelectedTeams] = useState([]);

  const handleTeamSelect = (selected) => {
    setSelectedTeams(selected);
    onSelect(selected);
  };
  const customTranslation = {
    selectSomeItems: "Select Teams...",
    allItemsAreSelected: "All items are selected.",
    selectAll: "Select All Teams",
    search: "Search Teams",
  };

  return (
    <div>
      <MultiSelect
        options={options}
        value={selectedTeams}
        onChange={handleTeamSelect}
        labelledBy={"Selected"}
        overrideStrings={customTranslation}
      />
    </div>
  );
};

export default MultiSelectDropdown;
