import { environments } from './config';

export class Environment {
  name: string;
  host: string;
  endpoint: string;
  url: string;

  constructor(options?: any) {
    this.name = options.name;
    this.host = options.host;
    this.endpoint = options.endpoint;
    this.url = options.url;
  }
}

let environment: Environment;

const isNil = (val) => val === undefined || val === null;
const isEmpty = (val: any) => val == null || !(Object.keys(val) || val).length;


export function getEnvironment() {
  let hostname: any = null;
  if (environment?.name != null) {
    return environment;
  }
  if (!isNil(window.location) && !isNil(window.location.hostname)) {
    hostname = `${window.location.protocol.toLowerCase()}//${window.location.hostname.toLowerCase()}`;
  }
  if (!isEmpty(hostname)) {
    const list = environments?.map((env) => new Environment(env));
    try {
      const idx = list.findIndex(
        (env) => env?.host?.toLowerCase() === hostname
      );
      if (idx > -1) {
        environment = list[idx];
      }
    } catch (err) {
      throw new Error(
        `Attempted to access an invalid set of environment variables: ${hostname}`
      );
    }
  }
  return environment;
}