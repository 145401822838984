import { useGameStore } from "../../../store/store";
import Game1AStep1 from "./Game1AStep1";
import Game1AStep2Transition from "./Game1AStep2Transition";
import Game1AStep2 from "./Game1AStep2";
import Game1AStep3Transition from "./Game1AStep3Transition";
import Game1AStep3 from "./Game1AStep3";
import Game1AHeader from "./Game1AHeader";
import axiosClient from "../../../config/apiClient";
import { useEffect } from "react";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable react-hooks/exhaustive-deps */
/* @ts-ignore */

export default function Game1A() {
  let { setInitialData } = useGameStore();
  const initialData = async () => {
    await axiosClient
      .get(`game1/getinitialpatron`)
      .then((resp) => {
        setInitialData(resp?.data?.initial_patron);
      });
  };

  useEffect(() => {
    initialData();
  }, []);

  const currentStep = useGameStore((state) => state.currentStep);
  return (
    <>
      <div className="max-w-screen flex min-h-screen flex-col items-center lg-max:hidden">
        <Game1AHeader />
        {
          {
            1: <Game1AStep1 />,
            2: <Game1AStep2Transition />,
            3: <Game1AStep2 />,
            4: <Game1AStep3Transition />,
            5: <Game1AStep3 />,
          }[currentStep]
        }
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
}
