// import { XMarkIcon } from "@heroicons/react/20/solid";
import { useGameStore } from "../../../store/store";
import { toast } from "react-toastify";

/* eslint-disable @typescript-eslint/no-unused-vars */
/* @ts-ignore */

export default function Game1AHeader() {
  const currentStep = useGameStore((state) => state.currentStep);
  const resetGame = useGameStore((state) => state.Game1reset);
  return (
    <div className="relative flex w-screen flex-row items-center justify-between py-4">
      <div className="flex flex-row justify-center">
        <button
          id="restgame"
          className="absolute left-2 top-0 h-full w-[8vw] items-center justify-center rounded-xl border-2 border-blue-700 bg-blue-100 text-center text-[90%] text-blue-800"
          onClick={() => {
            resetGame();
            toast.success(
              <>
                <div className="font-Helvetica text-lg font-bold tracking-wide">
                  Success
                </div>
                <div className="font-Helvetica text-sm tracking-wide">
                  Game Reset successfull!
                </div>
              </>,
              {
                position: "bottom-center",
                autoClose: 5000,
                type: "success",
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              }
            );
          }}
        >
          Reset Game
        </button>
      </div>

      <div className="flex flex-row gap-4">
        <div
          className={`h-3 rounded-xl sm:w-[60px] sm-max:w-[30px] md:w-[80px] lg:w-[100px] xl:w-[140px]  ${
            currentStep > 0 ? "bg-blue-500" : "bg-[#E5E5E5]"
          }`}
        />
        <div
          className={`h-3 rounded-xl sm:w-[60px] sm-max:w-[30px] md:w-[80px] lg:w-[100px] xl:w-[140px]  ${
            currentStep > 1 ? "bg-blue-500" : "bg-[#E5E5E5]"
          }`}
        />
        <div
          className={`h-3  rounded-xl sm:w-[60px] sm-max:w-[30px] md:w-[80px] lg:w-[100px] xl:w-[140px]  ${
            currentStep > 2 ? "bg-blue-500" : "bg-[#E5E5E5]"
          }`}
        />
        <div
          className={`h-3 rounded-xl sm:w-[60px] sm-max:w-[30px] md:w-[80px] lg:w-[100px] xl:w-[140px]  ${
            currentStep > 3 ? "bg-blue-500" : "bg-[#E5E5E5]"
          }`}
        />
        <div
          className={`h-3  rounded-xl sm:w-[60px] sm-max:w-[30px] md:w-[80px] lg:w-[100px] xl:w-[140px]  ${
            currentStep > 4 ? "bg-blue-500" : "bg-[#E5E5E5]"
          }`}
        />
      </div>
      <button></button>
    </div>
  );
}
