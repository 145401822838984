import MultiSelectDropdown from "../Alerts/components/multiselect";
import { useState } from "react"; // Update the import statement
import axiosClient from "../../../config/apiClient";
import { toast } from "react-toastify";

const Alerts = () => {
  const [AlertType, setAlertType] = useState("");
  const [isBlurActive, setIsBlurActive] = useState(false);
  const [data, setData] = useState([]);
  const [selectedTeams, setSelectedTeams] = useState([]); // State to store selected Teams
  const [message, setMessage] = useState("");

  const handleAlertTypeChange = (event) => {
    setAlertType(event.target.value);
  };
 //develop
  const handleMessageChange = (event) => {
    setMessage(event.target.value);
  };

  const handleTeamSelect = (selected) => {
    setSelectedTeams(selected);
  };

  const getAlert = async () => {
    // Removed parameters and used state variables directly
    if (selectedTeams.length !== 0) {
      try {
        // const response = await axiosClient.post(`notify/send/alert`, {
        const response = await axiosClient.post(`notify/pusher/alert`, {
          title: AlertType,
          message: message,
          teams: selectedTeams.map((team) => team.label),
        });
        setData(response.data);
        toast.success(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Alert Message sent
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Alert Message sent to selected Teams
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
        return response.data;
      } catch (error) {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Alert Request Failed
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Send Alert Notification Failed
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
        console.error("Error sending data to the server:", error);
      }
    } else {
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Please Select the teams
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
    }
  };

  return (
    <>
      <div className="flex h-screen flex-col items-center lg-max:hidden">
        <div className="md:h-14 lg:h-12 xl:h-16">
          <div className="flex-cols items-center justify-center md:h-14 lg:h-12 xl:h-16">
            <div className="mt-4 flex items-center justify-between">
              <div className="mr-8">
                <label
                  htmlFor="dropdown"
                  className="text-base font-bold text-white sm:text-xs sm-max:text-xs md:text-sm xl:text-xl"
                >
                  Alert Type
                </label>
              </div>
              <select
                id="AlertTypedropdown"
                className="md:text-md h-9 w-52 rounded-md border border-[#ccc] bg-[#D8D8D852] px-2 font-medium text-yellow-300 sm:h-4 sm:text-sm md:h-5 lg:h-6 xl:h-7 xl:text-lg 2xl:h-9"
                onChange={handleAlertTypeChange}
                value={AlertType}
              >
                <option value="" disabled>
                  Select Alert Type
                </option>
                <option value="Snow Storm Alert">Snow Storm Alert</option>
                <option value="Thunder Storm Alert">Thunder Storm Alert</option>
              </select>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="mr-8">
                <label className="text-base font-bold text-white sm:text-xs sm-max:text-xs md:text-sm xl:text-xl">
                  Alert Description
                </label>
              </div>
              <textarea
                id="message"
                className="md:text-md h-24 w-52 rounded-md bg-[#D8D8D852] px-2 py-1 font-medium text-yellow-300 sm:h-16 sm:text-sm md:h-20 lg:h-24 xl:h-28 xl:text-lg 2xl:h-36"
                onChange={handleMessageChange}
                value={message}
              ></textarea>
            </div>
            <div className="mt-4 flex items-center justify-between">
              <div className="mr-8">
                <label className="text-base font-bold text-white sm:text-xs sm-max:text-xs md:text-sm xl:text-xl">
                  Teams
                </label>
              </div>
              <MultiSelectDropdown onSelect={handleTeamSelect} />
            </div>
            <div className="mt-32 flex items-center justify-center">
              <button
                className="align-center ms-2 w-[35%] rounded-md bg-gradient-to-br from-[#279DF3] to-[#0466AD] text-center font-black text-white transition duration-200 hover:shadow-lg hover:shadow-[#3072D6] sm:h-4 sm:text-[10px] md:h-5 md:text-xs lg:h-6 lg:text-base xl:h-7 xl:text-xl 2xl:h-9"
                onClick={getAlert}
              >
                Send Alert
              </button>
            </div>
          </div>
        </div>
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};

export default Alerts;
