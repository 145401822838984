import RadioDisto from "./components/radiodistribution";
import InputWithIcon from "./components/groupbutton";
import CustomLineChart from "./components/charts/CustomLineChart";
import BarChart from "./components/charts/BarChart";
import "../game2/index.css";
import { useState, useEffect } from "react";
import { toast } from "react-toastify";
import { useGameStore } from "../../store/store";
import axiosClient from "../../config/apiClient";
const Play = () => {
  const [pintBuy, setPintBuy] = useState("");
  const [pintSell, setPintSell] = useState("");
  const [distribution, setDistribution] = useState(null);
  const { barChartData, setBarChartData, lineChartData, setLineChartData } =
    useGameStore();
  const [Lineloading, setLineloading] = useState(false);
  const [Barloading, setBarloading] = useState(false);
  const [isBlurActive, setIsBlurActive] = useState(false);
  const handlePintBuyChange = (event) => {
    setPintBuy(event.target.value);
    localStorage.setItem("pintBuy", event.target.value);
  };

  const handlePintSellChange = (event) => {
    setPintSell(event.target.value);
    localStorage.setItem("pintSell", event.target.value);
  };
  const handleRadioChange = (selectedOption: number) => {
    sendSelectedOption(selectedOption);
    setDistribution(selectedOption);
  };

  const sendSelectedOption = async (selectedOption: number) => {
    try {
      setBarloading(true);
      const response = await axiosClient.post(`game2/probabilitydistribution`, {
        distribution: selectedOption,
      });
      setBarChartData(response.data);
      setBarloading(false);
    } catch (error) {
      console.error("Error sending data to the server:", error);
    }
  };

  useEffect(() => {
    const storedDistribution = localStorage.getItem("radio");
    if (storedDistribution !== null) {
      setDistribution(parseInt(storedDistribution, 10));
    }
  }, []);

  const sendDataToServer = async () => {
    const buyPriceAsInt = parseInt(pintBuy, 10);
    const sellPriceAsInt = parseInt(pintSell, 10);
    if (!isNaN(buyPriceAsInt) && !isNaN(sellPriceAsInt)) {
      if (buyPriceAsInt < sellPriceAsInt) {
        const priceDifferencePercentage: number =
          ((sellPriceAsInt - buyPriceAsInt) / sellPriceAsInt) * 100;
        if (priceDifferencePercentage > 95) {
          setIsBlurActive(true);
          toast.error(
            <>
              <div className="font-Helvetica text-lg font-bold tracking-wide">
                Incorrect Value
              </div>
              <div className="font-Helvetica text-sm tracking-wide">
                The difference between Buy and Sell price is high!
              </div>
            </>,
            {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: false,
              closeOnClick: true,
              pauseOnHover: false,
              draggable: false,
              progress: undefined,
              theme: "colored",
              onClose: () => {
                setIsBlurActive(false);
              },
            }
          );
          return;
        }
      } else {
        setIsBlurActive(true);
        toast.error(
          <>
            <div className="font-Helvetica text-lg font-bold tracking-wide">
              Incorrect Value
            </div>
            <div className="font-Helvetica text-sm tracking-wide">
              Pint Buy Price should be less than Pint Sell Price!
            </div>
          </>,
          {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: false,
            draggable: false,
            progress: undefined,
            theme: "colored",
            onClose: () => {
              setIsBlurActive(false);
            },
          }
        );
        return;
      }
    } else {
      setIsBlurActive(true);
      toast.error(
        <>
          <div className="font-Helvetica text-lg font-bold tracking-wide">
            Empty Value
          </div>
          <div className="font-Helvetica text-sm tracking-wide">
            Please Enter Pint Buy/Sell Price!
          </div>
        </>,
        {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: false,
          draggable: false,
          progress: undefined,
          theme: "colored",
          onClose: () => {
            setIsBlurActive(false);
          },
        }
      );
      return;
    }

    try {
      setLineloading(true);
      const response = await axiosClient.post(`/game2/profitcalculation`, {
        buy_price: buyPriceAsInt,
        sell_price: sellPriceAsInt,
        distribution: distribution,
      });
      setLineChartData(response.data);
      setLineloading(false);
    } catch (error) {
      console.error("Error sending data to the server:", error);
    }
  };

  useEffect(() => { }, [barChartData]);
  useEffect(() => { }, [lineChartData]);
  return (
    <>
      <div className="mx-32 flex h-screen flex-col lg-max:hidden">
        <div className="lg:h-15 flex items-start justify-between md:h-20 xl:h-28">
          <RadioDisto onChange={handleRadioChange} />
          <InputWithIcon
            pintBuyChange={handlePintBuyChange}
            pintSellChange={handlePintSellChange}
            pintBuy={pintBuy}
            pintSell={pintSell}
            onEnterClick={sendDataToServer}
          />
        </div>

        <div className="item-start mb-2 h-1/2">
          {Lineloading ? (
            <div
              id="lineChartContainer"
              className="flex items-center justify-center bg-gray-50"
            >
              <div className="mr-4 h-10 w-10 animate-spin rounded-full border-t-2 border-blue-600"></div>
            </div>
          ) : (
            <div id="lineChartContainer">
              <CustomLineChart data={lineChartData} />
            </div>
          )}
        </div>

        <div className="h-1/5">
          {Barloading ? (
            <div
              id="barChartContainer"
              className="flex items-center justify-center bg-gray-50"
            >
              <div className="mr-4 h-10 w-10 animate-spin rounded-full border-t-2 border-blue-600"></div>
            </div>
          ) : (
            <div id="barChartContainer">
              <BarChart data={barChartData} />
            </div>
          )}
        </div>
        <button onClick={sendDataToServer}></button>
        {isBlurActive && (
          <div className="fixed inset-0 z-50 flex w-[100%] items-center justify-center overflow-y-auto overflow-x-hidden bg-[#3C1957] bg-opacity-50 backdrop-blur-[2px]"></div>
        )}
      </div>
      <div className="flex h-screen items-center justify-center text-center text-3xl font-semibold text-white md-max:visible lg-min:hidden">
        Please Rotate Your Screen
      </div>
    </>
  );
};
export default Play;
