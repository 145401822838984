import { createContext, useContext } from "react";
import { PusherTimeout } from "config/utils";

const PusherContext = createContext(null);

function PusherProvider({ pusher, children }) {
  
  const myInterval = setInterval(()=>{
      let status = PusherTimeout()
      if (status){
        pusher.disconnect()
        clearInterval(myInterval)
      }
    },900000)

  return (
    <PusherContext.Provider value={{ pusher }}>
      {children}
    </PusherContext.Provider>
  );
}

function usePusher() {
  const context = useContext(PusherContext);
  if (!context) {
    throw new Error("usePusher must be used within a PusherProvider");
  }

  const { pusher } = context;
  return pusher;
}

export { PusherProvider, usePusher };
