export const roundOff = (value: number | string): number => {
  return Math.round(+value);
};

export const key = 'secretkeyforroai';

export const PusherTimeout = () => {
  const gameStoreData = localStorage.getItem("game-store");
  const gameStoreObject = JSON.parse(gameStoreData);
  const authData = gameStoreObject.state.authData;
  const accessToken = authData?.access ||"";
  
  if (accessToken !== ""){

      var base64Url = accessToken.split('.')[1];
      var base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
      var jsonPayload = JSON.parse(decodeURIComponent(window.atob(base64).split('').map(function(c) {
      return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
      }).join('')));
      
      var tokenTime = (new Date(jsonPayload.exp * 1000)).toUTCString();
      // console.log("tokenTime",tokenTime)

      var systemTime = (new Date()).toUTCString()
      // console.log("systemTime",systemTime)

      if (systemTime > tokenTime){
          return true
      }else{
          return false
      }

  }else{
      return false
  }
}