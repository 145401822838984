// Auth Imports

import Play from "views/game2/index";
import About from "views/about";

// Game1A
import Game1A from "views/game1/game1A/game1A";

// Game1B
import Game1B from "views/game1/game1B";

// Game3
import { Game3 } from "views/game3/index";
//Leaderboard
import Leaderboard from "views/game3/leaderboard/leaderboard";

const routes = [
  {
    name: "About",
    layout: "/game",
    path: "about",
    component: <About />,
  },
  {
    name: "Leaderboard",
    layout: "/game",
    path: "leaderboard",
    component: <Leaderboard />,
  },
  {
    name: "Game1",
    layout: "/game",
    path: "game1A",
    component: <Game1A />,
  },
  {
    name: "Game2",
    layout: "/game",
    path: "game2",
    component: <Play />,
  },
  {
    name: "game1B",
    layout: "/game",
    path: "game1B",
    component: <Game1B />,
  },

  {
    name: "Game3",
    layout: "/game",
    path: "game3",
    component: <Game3 />,
  },
];

export default routes;
