export const environments = [
  {
    name: "dev",
    host: "https://game.dev.roaii.net",
    endpoint: "https://api.dev.roaii.net",
    url: "https://dev.roaii.net",
  },
  {
    name: "prod",
    host: "https://game.roaii.net",
    endpoint: "https://api.roaii.net",
    url: "https://roaii.net",
  },
  {
    name: "local",
    host: "http://localhost",
    endpoint: "http://localhost:8000",
    url: "https://dev.roaii.net",
  },
  {
    name: "qa",
    host: "https://game.qa.roaii.net",
    endpoint: "https://api.qa.roaii.net",
    url: "https://qa.roaii.net",
  },
  {
    name: "prod",
    host: "https://black-ocean-0c029210f.5.azurestaticapps.net",
    endpoint: "https://api.roaii.net",
    url: "https://roaii.net",
  },
];
