import { useMemo } from "react";
import "./historical.css";

import {
  useReactTable,
  getCoreRowModel,
  getFilteredRowModel,
  getPaginationRowModel,
  ColumnDef,
  flexRender,
} from "@tanstack/react-table";

interface HistoricalData {
  price: number;
  period: string;
  sale: number;
  revenue: number;
  cumulative_revenue: number;
  beginning_inventory: number;
  ending_inventory: number;
  date: string;
  teamname: string;
}

const HistoricalDataTable = ({ data, handleBackClick }) => {
  const columns = useMemo<ColumnDef<HistoricalData>[]>(
    () => [
      {
        header: "Price",
        cell: (row) => row.renderValue(),
        accessorKey: "price",
      },
      {
        header: "Time",
        cell: (row) => row.renderValue(),
        accessorKey: "period",
      },
      {
        header: "Sale",
        cell: (row) => row.renderValue(),
        accessorKey: "sale",
      },
      {
        header: "Revenue",
        cell: (row) => row.renderValue(),
        accessorKey: "revenue",
      },
      {
        header: "Cumulative Revenue",
        cell: (row) => row.renderValue(),
        accessorKey: "cumulative_revenue",
      },
      {
        header: "Beginning Inventory",
        cell: (row) => row.renderValue(),
        accessorKey: "beginning_inventory",
      },
      {
        header: "Ending Inventory",
        cell: (row) => row.renderValue(),
        accessorKey: "ending_inventory",
      },
      {
        header: "Date",
        cell: (row) => row.renderValue(),
        accessorKey: "date",
      },
    ],
    []
  );
  return (
    <Table
      {...{
        data,
        columns,
      }}
    />
  );

  function Table({
    data,
    columns,
  }: {
    data: HistoricalData[];
    columns: ColumnDef<HistoricalData>[];
  }) {
    const table = useReactTable({
      data,
      columns,
      // Pipeline
      getCoreRowModel: getCoreRowModel(),
      getFilteredRowModel: getFilteredRowModel(),
      getPaginationRowModel: getPaginationRowModel(),
      //
      debugTable: true,
    });
    const teamname = data[0]["teamname"];
    console.log(teamname);
    return (
      <div className="sm:1/4 z-1 m-auto flex justify-center px-10 py-2 md:w-1/2 ">
        <div className="w-full min-w-max flex-row justify-center">
          <div className="flex-cols flex justify-between ">
            <div className="w-full justify-center font-bold text-18 flex items-center text-center font-poppins uppercase text-[#fcd16b]">
              {teamname}
            </div>
            <div className="flex justify-end ">
              <button
                id="back-leaderboard"
                className="button-class flex rounded-md px-5 py-[3px] text-base text-white duration-300 ease-in-out hover:-translate-y-1 hover:bg-[#3072D6]"
                onClick={handleBackClick}
              >
                Back
              </button>
            </div>
          </div>
          <div className="table-container flex w-full min-w-max items-center justify-center">
            <table className="w-100 border-separate border-spacing-1 text-center">
              <thead className="bg-zinc-900 text-18 border-b p-10 font-poppins uppercase text-white">
                {table.getHeaderGroups().map((headerGroup) => (
                  <tr key={headerGroup.id}>
                    {headerGroup.headers.map((header) => {
                      return (
                        <th
                          key={header.id}
                          colSpan={header.colSpan}
                          style={{ padding: "10px" }}
                        >
                          {header.isPlaceholder ? null : (
                            <div>
                              {flexRender(
                                header.column.columnDef.header,
                                header.getContext()
                              )}
                            </div>
                          )}
                        </th>
                      );
                    })}
                  </tr>
                ))}
              </thead>
              <tbody>
                {table.getRowModel().rows.map((row, index) => (
                  <tr
                    key={row.id}
                    className={index % 2 === 0 ? "bg-zinc-500" : "bg-zinc-200"}
                  >
                    {row.getVisibleCells().map((cell, cellIndex) => (
                      <td
                        key={cell.id}
                        className={`text-yelloworange uppercase" px-3 py-0.5 text-center font-poppins text-lg ${
                          cellIndex === row.getVisibleCells().length - 1
                            ? "whitespace-nowrap"
                            : "whitespace-wrap"
                        }`}
                      >
                        <div className="flex flex-col">
                          <div className="item-center flex justify-center">
                            {flexRender(
                              cell.column.columnDef.cell,
                              cell.getContext()
                            )}
                          </div>
                        </div>
                      </td>
                    ))}
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
          <div className="pagination flex justify-end py-1">
            <button
              className="arrow-yelloworange rounded border border-white px-2  py-0.5"
              onClick={() => table.setPageIndex(0)}
              disabled={!table.getCanPreviousPage()}
              id="first-page"
            >
              {"<<"}
            </button>
            <button
              className="arrow-yelloworange rounded border border-white px-2  py-0.5"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
              id="prev-page"
            >
              {"<"}
            </button>
            <button
              className="arrow-yelloworange rounded border border-white px-2  py-0.5"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
              id="next-page"
            >
              {">"}
            </button>
            <button
              className="arrow-yelloworange rounded border border-white px-2  py-0.5"
              onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              disabled={!table.getCanNextPage()}
              id="last-page"
            >
              {">>"}
            </button>
            <span className="arrow-yelloworange flex items-center gap-1 px-4">
              <div>Page</div>
              <strong>
                {table.getState().pagination.pageIndex + 1} of{" "}
                {table.getPageCount()}
              </strong>
            </span>
            {/* <span className="flex items-center gap-2">
              | Go to page:
              <input
                type="number"
                defaultValue={table.getState().pagination.pageIndex + 1}
                onChange={(e) => {
                  const page = e.target.value ? Number(e.target.value) - 1 : 0;
                  table.setPageIndex(page);
                }}
                className="w-16 rounded border p-1 gap-2"
              />
            </span> */}
            {/* <select
              value={table.getState().pagination.pageSize}
              onChange={(e) => {
                table.setPageSize(Number(e.target.value));
              }}
            >
              {[5, 7, 9].map((pageSize) => (
                <option key={pageSize} value={pageSize}>
                  Show {pageSize}
                </option>
              ))}
            </select> */}
          </div>
        </div>
      </div>
    );
  }
};
export default HistoricalDataTable;

// interface ReactTableProps<T extends object> {
//   data: any[]; // Accept any type for data
//   columns: ColumnDef<T>[];
//   isPlayer: boolean;
//   pageSize: number;
//   pageIndex: number;
//   onChange: OnChangeFn<PaginationState>;
// }
// export const Table = <T extends object>({
//   data,
//   columns,
// }: ReactTableProps<T>) => {
//   const table = useReactTable({
//     data,
//     columns,
//     getCoreRowModel: getCoreRowModel(),
//     getFilteredRowModel: getFilteredRowModel<data>(),
//     initialState: {
//       pagination: {
//         pageSize: 10, // Number of rows per page
//         pageIndex: 0, // Current page index
//       },
//     },
//   });

//   return (
//     <div className="sm:1/4 m-auto flex justify-center px-10 py-5 md:w-1/2">
//       <div className="flex-row justify-center">
//       <div className="table-container flex items-center justify-center w-full min-w-max">
//         <table className="w-10/12 border-separate border-spacing-1 text-center">
//           <thead className="bg-zinc-900 text-16 border-b font-poppins uppercase text-white text-white">
//             {table.getHeaderGroups().map((headerGroup) => (
//               <tr key={headerGroup.id}>
//                 {headerGroup.headers.map((header, index) => (
//                   <th
//                     key={header.id}
//                     className={`font-500 px-4 py-1 text-xl ${
//                       index === headerGroup.headers.length - 1
//                         ? "whitespace-nowrap"
//                         : "whitespace-wrap"
//                     }`}
//                   >
//                     {header.isPlaceholder
//                       ? null
//                       : flexRender(
//                           header.column.columnDef.header,
//                           header.getContext()
//                         )}
//                   </th>
//                 ))}
//               </tr>
//             ))}
//           </thead>
//           <tbody>
//             {table.getRowModel().rows.map((row, index) => (
//               <tr
//                 key={row.id}
//                 className={index % 2 === 0 ? "bg-zinc-500" : "bg-zinc-200"}
//               >
//                 {row.getVisibleCells().map((cell, cellIndex) => (
//                   <td
//                     key={cell.id}
//                     className={`arrow-yelloworange uppercase" px-3 py-1 text-center font-poppins text-lg ${
//                       cellIndex === row.getVisibleCells().length - 1
//                         ? "whitespace-nowrap"
//                         : "whitespace-wrap"
//                     }`}
//                   >
//                     <div className="flex flex-col">
//                       <div className="item-center flex justify-center">
//                         {flexRender(
//                           cell.column.columnDef.cell,
//                           cell.getContext()
//                         )}
//                       </div>
//                     </div>
//                   </td>
//                 ))}
//               </tr>
//             ))}
//           </tbody>
//         </table>
//         </div>
//         <div className="pagination flex items-center justify-center">

//         </div>
//         </div>
//     </div>
//   );

// };

// const HistoricalData = () => {
//   const [pageSize, setPageSize] = useState(10); // Number of rows per page
//   const [pageIndex, setPageIndex] = useState(0); // Current page index

//   const cols = useMemo<ColumnDef<data>[]>(
//     () => [
//       {
//         header: "Price",
//         cell: (row) => row.renderValue(),
//         accessorKey: "price",
//       },
//       {
//         header: "Time",
//         cell: (row) => row.renderValue(),
//         accessorKey: "period",
//       },
//       {
//         header: "Sale",
//         cell: (row) => row.renderValue(),
//         accessorKey: "sale",
//       },
//       {
//         header: "Revenue",
//         cell: (row) => row.renderValue(),
//         accessorKey: "revenue",
//       },
//       {
//         header: "Cumulative Revenue",
//         cell: (row) => row.renderValue(),
//         accessorKey: "cumulative_revenue",
//       },
//       {
//         header: "Beginning Inventory",
//         cell: (row) => row.renderValue(),
//         accessorKey: "beginning_inventory",
//       },
//       {
//         header: "Ending Inventory",
//         cell: (row) => row.renderValue(),
//         accessorKey: "ending_inventory",
//       },
//       {
//         header: "Date",
//         cell: (row) => row.renderValue(),
//         accessorKey: "date",
//       },
//     ],
//     []
//   );
//   return (
//     <div className="mt-20 min-h-screen">
//       <Table
//         data={data}
//         columns={cols}
//         isPlayer={true}
//         pageSize={pageSize} // Number of rows per page
//         pageIndex={pageIndex} // Current page index
//         onChange={(paginationState) => {
//           setPageSize(pageSize);
//           setPageIndex(pageIndex);
//         }}
//       />
//       {/* .... */}
//     </div>
//   );
// };

// export default HistoricalData;
