import { StateCreator } from "zustand";

export interface authData {
  refresh: String;
  access: String;
  username: String;
  type: String;
  tenant_id: Number;
  isPlayer: Boolean;
}

export interface authSlice {
  authData: authData | null; // Initialize as null
  setauthData: (data: authData | null) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

export const createauthSlice: ImmerStateCreator<authSlice> = (set) => ({
  authData: null,
  setauthData: (data) =>
    set((state) => {
      state.authData = data;
    }),
});
