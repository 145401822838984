// chartUtils.ts
const removeSlidderAnnotation = (functiondata, id) => {
    
  let { chartRef } = functiondata;
  const chart = chartRef.current;
  const annotations = chart.annotations;
  for (let i = annotations.length - 1; i > -1; --i) {
    if (annotations[i].options.id === id) {
      chart.removeAnnotation(annotations[i].options.id);
    }
  }
};
function calculateLabelY(functiondata) {
  const { chartRef, maxinterval, LabelPointDataPoint } = functiondata;
  const chart = chartRef.current;
  let { cost, profit, exp_revenue } = LabelPointDataPoint;
  const label1Y = chart.yAxis[0].max - maxinterval;
  const label3Y = chart.yAxis[0].min + maxinterval;
  const label2Y = (label1Y + label3Y) / 2;
  const values = {
    cost: cost,
    profit: profit,
    exp_revenue: exp_revenue,
  };
  const sortedVariableNames = Object.keys(values).sort(
    (a, b) => values[a] - values[b]
  );
  const labelValues = [label3Y, label2Y, label1Y];
  const assignedValues = {};
  for (
    let i = 0;
    i < Math.min(sortedVariableNames.length, labelValues.length);
    i++
  ) {
    assignedValues[sortedVariableNames[i]] = labelValues[i];
  }
  return assignedValues;
}
function createLabel(functiondata) {
  const {
    chartRef,
    data,
    LabelPoint,
    LabelPointDataPointIndex,
    LabelPointDataPoint,
  } = functiondata;
  const assignedValues = calculateLabelY(functiondata);
  let sliderlabel;
  const chart = chartRef.current;
  if (LabelPoint === Math.max(...data.purchase)) {
    sliderlabel = {
      id: "tooltip",
      draggable: "",
      labels: [
        {
          verticalAlign: "top",
          align: "right",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["cost"], //chart.yAxis[0].max - maxYValue,
            xAxis: 0,
            yAxis: 0,
          },
          x: -10,
          y: 10,
          backgroundColor: "navy",
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
              if (LabelPointDataPoint) {
                const { cost } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight:500; font-size:0.9vw;letter-spacing: 0.063rem;">Cost:&nbsp;$${cost}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
        {
          verticalAlign: "top",
          align: "right",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["profit"],
            xAxis: 0,
            yAxis: 0,
          },
          x: -10,
          y: 10,
          backgroundColor: "navy",
          //distance: 0.8,
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
              if (LabelPointDataPoint) {
                const { profit } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight: 500;font-size:0.9vw;letter-spacing: 0.063rem;">Profit:&nbsp;$${profit}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
        {
          verticalAlign: "top",
          align: "right",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["exp_revenue"], //chart.yAxis[0].min,
            xAxis: 0,
            yAxis: 0,
          },
          x: -10,
          y: 10,
          backgroundColor: "navy",
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
              if (LabelPointDataPoint) {
                const { exp_revenue } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight: 500;font-size:0.9vw;letter-spacing: 0.063rem;">Revenue:&nbsp;$${exp_revenue}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
      ],
    };
  } else {
    sliderlabel = {
      id: "tooltip",
      draggable: "",
      labels: [
        {
          verticalAlign: "top",
          align: "left",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["cost"], //chart.yAxis[0].max - maxYValue,
            xAxis: 0,
            yAxis: 0,
          },
          x: 10,
          y: 10,
          backgroundColor: "navy",
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
                
              if (LabelPointDataPoint) {
                const { cost } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight: 500; font-size:0.9vw;letter-spacing: 0.063rem;">Cost:&nbsp;$${cost}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
        {
          verticalAlign: "top",
          align: "left",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["profit"],
            xAxis: 0,
            yAxis: 0,
          },
          x: 10,
          y: 10,
          backgroundColor: "navy",
          //distance: 0.8,
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
              if (LabelPointDataPoint) {
                const { profit } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight: 500;font-size:0.9vw;letter-spacing: 0.063rem;">Profit:&nbsp;$${profit}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
        {
          verticalAlign: "top",
          align: "left",
          zindex: 999,
          point: {
            x: LabelPoint ? LabelPoint : 0,
            y: assignedValues["exp_revenue"], //chart.yAxis[0].min,
            xAxis: 0,
            yAxis: 0,
          },
          x: 10,
          y: 10,
          backgroundColor: "navy",
          // y: rev_d,
          //distance: 0.8,
          className: "no-pointer-events-label",
          formatter: function (this: any): string {
            if (LabelPoint === data.purchase[LabelPointDataPointIndex]) {
              if (LabelPointDataPoint) {
                const { exp_revenue } = LabelPointDataPoint;
                const formattedTooltips = [
                  `<div style="color: yellow;font-weight: 500;font-size:0.9vw;letter-spacing: 0.063rem;">Revenue:&nbsp;$${exp_revenue}</div>`,
                ];
                return formattedTooltips.join("");
              }
            }
          },
        },
      ],
    };
  }
  chart.addAnnotation(sliderlabel);
  chart.redraw();
}
function SetColorandLabel(functiondata) {
  let {
    xCoordinate,
    chartRef,
    xAnnotation,
  } = functiondata;
  const chart = chartRef.current;
  const difference = Math.abs(xAnnotation - xCoordinate);
  const percentageDifference = (difference / 240) * 100;
  let ColorFill;
  if (percentageDifference <= 1) {
    ColorFill = "#39F141";
  } else if (percentageDifference <= 25 && percentageDifference >= 2) {
    ColorFill = "#4CAF50";
  } else if (percentageDifference <= 50 && percentageDifference >= 26) {
    ColorFill = "#FFC107";
  } else if (percentageDifference <= 80 && percentageDifference >= 51) {
    ColorFill = "#1D7CC7";
  } else {
    ColorFill = "#D9D9D9";
  }
  const circleElement = document.querySelector("circle");
  const pathElement = document.querySelector(".highcharts-tracker-line");
  circleElement.setAttribute("fill", ColorFill);
  pathElement.setAttribute("stroke", ColorFill);
  removeSlidderAnnotation(functiondata, "tooltip");
  createLabel(functiondata);
  chart.redraw();
}
function computeNearestVal(functiondata) {
  const {
    chartRef,
    data,
    xAnnotation,
    lineshape,
    circleshape,
  } = functiondata;
  const chart = chartRef.current;
  const nearestValue = data.purchase.reduce((prev, curr) => {
    return Math.abs(curr - xAnnotation) < Math.abs(prev - xAnnotation) ? curr : prev;
  });

  if (chart) {
    const linePoint = lineshape.points[0];
    (linePoint as any).x = nearestValue;
    (linePoint as any).y = 20;
    const linePoint2 = lineshape.points[1];
    (linePoint2 as any).x = nearestValue;
    (linePoint2 as any).y = 2000;
    const circlePoint = circleshape.points[0];
    (circlePoint as any).x = nearestValue;
    (circlePoint as any).y = 16;
    const circlePoint2 = lineshape.points[1];
    (circlePoint2 as any).x = nearestValue;
    (circlePoint2 as any).y = 1000;
    chart.redraw();
    return nearestValue;
  }
}
export {
  removeSlidderAnnotation,
  calculateLabelY,
  createLabel,
  SetColorandLabel,
  computeNearestVal,
};
