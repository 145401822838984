import { StateCreator } from "zustand";

export interface Patron {
  id: number;
  name: string;
  actualDaily: number;
  lastYear: number;
  previousFriday1: number;
  previousFriday2: number;
  previousFriday3: number;
  previousFriday4: number;
  previousFriday5: number;
  cluster: string;
}

export interface InitialData {
  id: number;
  actualDaily: number;
  lastYear: number;
  checksum: number;
  previousFriday1: number;
  previousFriday2: number;
  previousFriday3: number;
  previousFriday4: number;
  previousFriday5: number;
  cluster: string;
  name: string;
}

export interface Game1ASlice {
  game1AStep1PatronList: Array<Patron>;
  currentStep: number;
  incrementCurrentStep: () => void;
  decrementCurrentStep: () => void;
  sortPatronListBy: (fieldName: keyof Omit<Patron, "name" | "cluster">) => void;
  resetPatronList: () => void;
  step1GroupIds: Patron["id"][];
  step1SliderValue: number[];
  setStep1SliderValue: (value: number[]) => void;
  step1IsGrouped: boolean;
  setStep1IsGrouped: (isGrouped: boolean) => void;
  step1IsSorted: boolean;
  setStep1IsSorted: (isSorted: boolean) => void;
  step2FirstGroupIds: Patron["id"][];
  step2SecondGroupIds: Patron["id"][];
  step3GroupIds: Patron["id"][];
  setStep1Group: (ids: Patron["id"][]) => void;
  setStep2FirstGroup: (ids: Patron["id"][]) => void;
  setStep2SecondGroup: (ids: Patron["id"][]) => void;
  clusterPatronList: () => void;
  clusters: Patron["id"][][];
  clusterColors: Record<Patron["cluster"], string>;
  isGame1ACompleted: boolean;
  setIsGame1ACompleted: (completed: boolean) => void;
  Game1reset: () => void;
  setInitialData: (data: Array<InitialData>) => void;
}

type ImmerStateCreator<T> = StateCreator<
  T,
  [["zustand/immer", never], never],
  [],
  T
>;

let initialState = {
  currentStep: 1,
  game1AStep1PatronList: [],
  step1GroupIds: [],
  step1SliderValue: [0],
  step1IsGrouped: false,
  step1IsSorted: false,
  step2FirstGroupIds: [],
  step2SecondGroupIds: [],
  step3GroupIds: [],
  clusters: [],
  clusterColors: {
    Regular: "bg-color8",
    "Bingo Night": "bg-color9",
    Special: "bg-color10",
    Left: "bg-color7",
    Celebrator: "bg-color6",
  },
  isGame1ACompleted: false,
};

export const createGame1ASlice: ImmerStateCreator<Game1ASlice> = (set) => ({
  setInitialData: (data) =>
    set((state) => {
      // state.initialPatronData = data;
      initialState.game1AStep1PatronList = data;
      state.game1AStep1PatronList = data;
      let clusters = [
        data
          .filter((patron) => patron.cluster === "Celebrator")
          .map((patron) => patron.id),
        data
          .filter((patron) => patron.cluster === "Left")
          .map((patron) => patron.id),
        data
          .filter((patron) => patron.cluster === "Regular")
          .map((patron) => patron.id),
        data
          .filter((patron) => patron.cluster === "Bingo Night")
          .map((patron) => patron.id),
        data
          .filter((patron) => patron.cluster === "Special")
          .map((patron) => patron.id),
      ];
      state.clusters = clusters;
      initialState.clusters = clusters;
    }),

  currentStep: initialState.currentStep,
  incrementCurrentStep: () =>
    set((state) => ({ currentStep: state.currentStep + 1 })),
  decrementCurrentStep: () =>
    set((state) => {
      if (state.currentStep > 1) return { currentStep: state.currentStep - 1 };
      return { currentStep: 1 };
    }),
  game1AStep1PatronList: initialState.game1AStep1PatronList,
  sortPatronListBy: (fieldName) =>
    set((state) => {
      state.game1AStep1PatronList = state.game1AStep1PatronList.sort(
        (a, b) => a[fieldName] - b[fieldName]
      );
    }),
  resetPatronList: () =>
    set((state) => {
      state.game1AStep1PatronList = [];
    }),
  step1GroupIds: initialState.step1GroupIds,
  step1SliderValue: initialState.step1SliderValue,
  step1IsGrouped: initialState.step1IsGrouped,
  step1IsSorted: initialState.step1IsSorted,
  step2FirstGroupIds: initialState.step2FirstGroupIds,
  step2SecondGroupIds: initialState.step2SecondGroupIds,
  step3GroupIds: initialState.step2SecondGroupIds,
  setStep1Group: (ids) =>
    set((state) => {
      state.step1GroupIds = ids;
    }),
  setStep1SliderValue: (number) =>
    set((state) => {
      state.step1SliderValue = number;
    }),
  setStep1IsGrouped: (isGrouped) =>
    set((state) => {
      state.step1IsGrouped = isGrouped;
    }),
  setStep1IsSorted: (isSorted) =>
    set((state) => {
      state.step1IsSorted = isSorted;
    }),
  setStep2FirstGroup: (ids) =>
    set((state) => {
      state.step2FirstGroupIds = ids;
    }),
  setStep2SecondGroup: (ids) =>
    set((state) => {
      state.step2SecondGroupIds = ids;
    }),
  clusterPatronList: () =>
    set((state) => {
      const customClusterOrder = [
        "Celebrator",
        "Left",
        "Regular",
        "Bingo Night",
        "Special",
      ];

      state.game1AStep1PatronList = state.game1AStep1PatronList.sort(
        (a, b) =>
          customClusterOrder.indexOf(a["cluster"]) -
          customClusterOrder.indexOf(b["cluster"])
      );
    }),

  clusters: initialState.clusters,
  clusterColors: initialState.clusterColors,
  isGame1ACompleted: initialState.isGame1ACompleted,
  setIsGame1ACompleted: (completed) =>
    set((state) => {
      state.isGame1ACompleted = completed;
    }),
  Game1reset: () => set(initialState),
});
