import React, { useRef, useEffect } from "react";
import * as Highcharts from "highcharts";
import HighchartsReact from "highcharts-react-official";

/* eslint-disable react-hooks/exhaustive-deps */

interface BarChartProps {
  data: { Purchase: number[]; probability: number[]; chart_name: string };
}

const BarChart = (props: BarChartProps) => {
  const { data } = props;
  const defaultchart_name = "Probablity Distribution";
  let chartData: [number, number][] = [];

  if (data === null || data === undefined) {
    const defaultPurchase = [0, 40, 80, 120, 160, 200, 240, 280];
    const defaultProbability = [];

    chartData = defaultPurchase.map((purchase, index) => [
      purchase,
      defaultProbability[index],
    ]);
  } else {
    const probabilityData = data.probability.map(
      (percentage) => percentage / 100
    );

    chartData = data.Purchase.map((purchase, index) => [
      purchase,
      probabilityData[index],
    ]);
  }
  const options: Highcharts.Options = {
    title: {
      text: data?.chart_name || defaultchart_name,
      align: "center",
      margin: 0,
      style: {
        color: "#777",
        fontWeight: "700",
        fontSize: "15px",
        letterSpacing: "1px",
      },
    },
    series: [
      {
        type: "column",
        data: chartData,
        color: "#4F71BE",
        dataLabels: {
          enabled: false,
        },
        index: 2,
        showInLegend: false,
      },
    ],
    xAxis: {
      gridLineWidth: 0,
      zoomEnabled: true,
      title: {
        text: "Beer Demand Level",
        align: "middle",

        style: {
          color: "#262626",
          fontWeight: "600",
          fontSize: "12px",
          letterSpacing: "1px",
        },
      },
      tickInterval: 40,
      tickWidth: 0.5,
      lineWidth: 0.5,
      lineColor: "#949494",
      tickColor: "#949494",
      minPadding: 0.1,
      maxPadding: 0.1,
      min: 0,
      max: 280,
      labels: {
        style: {
          color: "grey",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: "700",
          letterSpacing: "1px",
        },
      },
    },
    yAxis: {
      title: {
        text: "Likelihood",
        align: "middle",
        style: {
          color: "#262626",
          fontWeight: "600",
          fontSize: "12px",
          letterSpacing: "1px",
        },
      },
      min: 0,
      max: 1,
      tickInterval: 0.25,
      tickWidth: 0.5,
      lineWidth: 0.5,
      lineColor: "#949494",
      tickColor: "#949494",
      minPadding: 0.1,
      maxPadding: 0.1,

      labels: {
        format: "{value:.2f}",
        style: {
          color: "grey",
          fontFamily: "Roboto",
          fontSize: "10px",
          fontWeight: "700",
          letterSpacing: "1px",
        },
      },
    },
    chart: {
      renderTo: "container",
      spacingBottom: 1,
      spacingTop: 2,
    },
    credits: {
      enabled: false,
    },
  };

  const chartComponentRef = useRef<HighchartsReact.RefObject>(null);

  useEffect(() => {
    if (chartComponentRef.current) {
      chartComponentRef.current.chart.update({
        series: [
          {
            type: "column",
            data: chartData,
          },
        ],
      });
    }
  }, [data]);

  return (
    <HighchartsReact
      highcharts={Highcharts}
      options={options}
      ref={chartComponentRef}
      containerProps={{
        className: "custom-chart-container",
      }}
    />
  );
};

export default React.memo(BarChart);
