import Links from "../sidebar/components/Links";
import routes from "routes";
import instructorroutes from "instructor-routes";
import logo from "../../assets/game1/logo.svg";
import Card from "../card/CardMenu";
// import Game3Menu from "../card/Game3";
import image from "../../assets/chatbot/questionmark.png";
import {useAuth} from  "config/AuthDataProvider";


const Header = ({ setIsOpen }) => {
  const authData = useAuth();

  return (
    <div className="flex h-[8vh] items-center text-center justify-between bg-white px-[2vw]">
      <div className="flex items-center font-Helvetica tracking-widest sm-max:text-[2.2vw] sm:text-[2vw] md:text-[1.8vw] lg:text-[1.7vw] xl:text-[1.4vw]">
      {authData && authData.isPlayer === true ? (
            <Links routes={routes} />
          ) : (
            <Links routes={instructorroutes} />
          )}

      </div>
      <div className="flex items-center gap-[0.5vw]">
      {/* <div className="flex items-center font-Helvetica tracking-widest text-xl bg-blue-700 rounded-sm text-white px-2 py-1">
        Beta
      </div> */}
      {authData && authData.isPlayer === true ? (
        <div
          className="flex cursor-pointer flex-row tracking-widest !font-[500] text-blue-800 hover:text-[#23527c] sm-max:text-[2.2vw] sm:text-[2vw] md:text-[1.8vw] lg:text-[1.7vw] xl:text-[1.4vw]"
          onClick={() => setIsOpen(true)}
        >
          <img src={image} alt="askme" className="sm-max:h-[2.9vw] sm-max:w-[2.9vw] sm:h-[2.7vw] sm:w-[2.7vw] md:h-[2.5vw] md:w-[2.5vw] lg:h-[2.4vw] lg:w-[2.4vw] xl:h-[2.2vw] xl:w-[2.2vw]"></img>
          Askme
        </div>
         ) : (
          null)}
        <Card />
        <img
          src={logo}
          className="flex sm-max:h-[4vw] sm-max:w-[4vw] sm:h-[3.5vw] sm:w-[3.5vw] md:h-[3.5vw] md:w-[3.5vw] lg:h-[4vw] lg:w-[4vw] xl:h-[4.2vw] xl:w-[4.2vw]"
          alt="logo"
        />
      </div>
    </div>
  );
};
export default Header;
