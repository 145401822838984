import SignIn from "views/auth/SignIn.tsx";

const authRoutes = [
  {
    name: "Login",
    layout: "/auth",
    path: "login",
    component: <SignIn />,
  },
];

export default authRoutes;
